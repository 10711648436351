import { WidgetId } from '../../../types/Widget';

export const shouldFetchAirdaleInfo = (widgetType: string, isMerchantPage: boolean): boolean => {
  if (
    (widgetType !== WidgetId.VULTURE &&
      widgetType !== WidgetId.TILE &&
      widgetType !== WidgetId.SCROLLABLE_TILE) ||
    isMerchantPage
  ) {
    return false;
  }

  return true;
};
