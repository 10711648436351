export enum WidgetId {
  BESTOF = 'bestof',
  BUNDLE = 'bundle',
  BUTTON = 'button',
  ANF_BUTTON = 'anfbutton',
  ANF_MULTIMODEL = 'anfmultimodel',
  ANF_COLLECTION_TEMPLATE = 'anfcollectiontemplate',
  COMPARISON = 'comparison',
  DEAL = 'deal',
  DOTW = 'dotw',
  EDITORS_PICK = 'editorspick',
  GAME_PLATFORM = 'gameplatform',
  TABS = 'tabs',
  INBODY = 'inbody',
  LABELS = 'labels',
  LINK = 'link',
  MAGAZINE_SUBSCRIPTIONS = 'magazinesubscriptions',
  MERCHANT_LINK = 'merchantlink',
  MERCHANT_OVERVIEW = 'merchantoverview',
  MONA_WIDGET = 'monawidget',
  MOST_POPULAR_OFFERS = 'mostpopularoffers',
  MULTI_MODEL_REVIEW = 'multimodelreview',
  NIGHTHAWK = 'nighthawk',
  OVERLAY = 'overlay',
  PARTNER = 'partner',
  PEACOCK = 'peacock',
  POCKET = 'pocket',
  PRICE = 'price',
  PRODUCT_NAME = 'productname',
  PRODUCT_BLOCK = 'productblock',
  PROMOTION = 'promotion',
  REVIEW = 'review',
  SCROLLABLE_TILE = 'scrollabletile',
  SEASONAL = 'seasonal',
  SHOP = 'shop',
  SIMPLE = 'simple',
  SPECS = 'specs',
  STICKY = 'sticky',
  STREAMING = 'streaming',
  TILE = 'tile',
  USER_REVIEWS = 'userreviews',
  VULTURE = 'vulture',
  BROADBAND = 'broadband',

  // aliases TODO: rework in future ( its made for getWidget.ts currently)
  ALIAS_FULLPRICE = 'full-price',
  ALIAS_PRODUCTNAME = 'product-name',
  ALIAS_MODELNAME = 'modelname',
  ALIAS_ALPINE = 'alpine',
  ALIAS_TABLEPRICE = 'tableprice',

  MOCKID = 'mockId', // for getUseAnalyticsMock.ts
  TEST = 'test', // for getAllFreatures.ts
  PROMOTION_FALLBACK = 'promotionfallback',
  GENERIC_FALLBACK = 'genericfallback',
  _1234 = '1234', // placehodler for LocalEndpoint
  _12 = '12', // placeholder for WidgetProcessor tests
}

export interface Widget {
  id: WidgetId;
  dir: string;
  label: string;
  gaLabel: string;
  endpoint: string;
  aliases?: WidgetId[];
  sites: string[];
  group: string;
}
