import getDate from './getDate';

export default (
  endTime: number,
  language: string,
  area: string,
  format?: Intl.DateTimeFormatOptions,
): string => {
  const date = getDate(endTime);
  if (format) {
    return new Intl.DateTimeFormat(language, format).format(date);
  }

  const shortWeekday = new Intl.DateTimeFormat(language, { weekday: 'short' }).format(date);
  const shortMonth = new Intl.DateTimeFormat(language, { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat(language, { day: 'numeric' }).format(date);
  const year = new Intl.DateTimeFormat(language, { year: 'numeric' }).format(date);

  switch (area) {
    case 'US':
      return `${shortWeekday}, ${shortMonth} ${day}, ${year}`;
    default:
      return `${shortWeekday}, ${day} ${shortMonth}, ${year}`;
  }
};
