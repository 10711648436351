import { getSelectedFilter } from './getSelectedFilter';
import type { HawkWidgetAdvancedState } from './model';

interface GetFilterValueMultiSelectParams {
  selectedFilters: HawkWidgetAdvancedState['selectedFilters'];
  activeTab: HawkWidgetAdvancedState['activeTab'];
  filterKey?: string;
  value?: string;
}

export const getFilterValueMultiSelect = ({
  selectedFilters,
  activeTab,
  filterKey,
  value,
}: GetFilterValueMultiSelectParams): string => {
  const currentValues = getSelectedFilter<string>(selectedFilters, activeTab, filterKey, '');
  let values: string[] = [];
  if (value) {
    // Convert the value string into an array
    // Filter out empty string values - when no filter is selected this means the
    // array is empty instead of [""]
    values = currentValues
      .toString()
      .split(',')
      .filter((_) => _);

    // Add the new value to the array - if it isn't already there
    if (!values.includes(value)) {
      values.push(value);
    } else {
      // Otherwise remove it from the array
      const index = values.indexOf(value);
      values.splice(index, 1);
    }
  } else {
    // The 'Any' option was selected
    values = [''];
  }

  return values.join(',');
};
