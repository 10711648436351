import React from 'react';

import styles from '../styles/lazy-image.css';

import type { ImgParams } from './model';

export const createImgElement = (imgParams: ImgParams): ImgParams => {
  const {
    srcSet,
    attributes,
    src,
    alt,
    className,
    width,
    height,
    container,
    inputContainer,
    onClick,
  } = imgParams;

  return {
    ...imgParams,
    element: onClick ? (
      <input
        {...attributes}
        type="image"
        src={src}
        alt={alt}
        title={alt}
        className={className && styles[className] ? styles[className] : styles.image}
        draggable="false"
        width={width}
        height={height}
        ref={inputContainer}
        // if we want to stay true to the hawk widget advaned we should use chage event here
        onClick={onClick as (event: React.SyntheticEvent<HTMLInputElement>) => void}
        onKeyPress={onClick as (event: React.SyntheticEvent<HTMLInputElement>) => void}
      />
    ) : (
      <img
        {...attributes}
        src={src}
        srcSet={srcSet}
        alt={alt}
        title={alt}
        className={className && styles[className] ? styles[className] : styles.image}
        draggable="false"
        loading="lazy"
        width={width}
        height={height}
        ref={container}
      />
    ),
  };
};
