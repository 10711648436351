import { WidgetData } from '../model';

export const processDealWidgetStar = (
  star: WidgetData['data']['star'],
  starLabel: WidgetData['data']['starLabel'],
): Record<string, string> => {
  if (star) {
    return {
      star: 'hero',
      ...(starLabel ? { 'star-label': starLabel } : {}),
    };
  }

  return {};
};
