import convertUnderscoreCaseToSentenceCase from './convertUnderscoreCaseToSentenceCase';

type LabelKey = `filter_label[${Lowercase<string>}]`;
/**
 * Get the labels formatted as api params (e.g. filter_label[text_colour])
 */
export const getLabelAPIParams = (
  attributes: Record<string, unknown>,
): Record<LabelKey, unknown> => {
  return Object.keys(attributes).reduce((result, key) => {
    // drop support for any label in old editorial widgets that don't use the new label format
    if (
      key.indexOf('label') >= 0 &&
      key.indexOf('filter_label') === -1 &&
      key !== 'distinct_label' &&
      key.match(/Text|Boolean|Number/g)
    ) {
      /* Handle dynamic labels differently - change the name to an underscore cased string
      Also support the label type prefix (text, number etc.)
      E.g. labelMaterial -> material, gamePlatform -> game_platform,
      textGamePlatform -> text_game_platform */
      const labelName = key
        .replace(/label/, '')
        .replace(/([A-Z])/g, (a) => `_${a.toLowerCase()}`)
        .substring(1);
      result[`filter_label[${labelName}]`] = attributes[key];
    }

    return result;
  }, {});
};

type DatasetLabelKey = `label${Uppercase<string>}${string}`;
/**
 * Get the labels formatted as dataset params (e.g. labelTextColour))
 */
export const getLabelDatasetParams = (
  params: Record<string, unknown>,
): Record<DatasetLabelKey, unknown> => {
  const result = {};

  Object.keys(params).forEach((key) => {
    if (
      (key.indexOf('filter_label') >= 0 && key.match(/text_|boolean_|number_/g)) ||
      (key.indexOf('filter_label') === -1 && key.indexOf('label') >= 0 && key !== 'distinct_label')
    ) {
      /* Handle dynamic labels differently - change the name to an underscore cased string
      Also support the label type prefix (text, number etc.)
      E.g. labelMaterial -> material, gamePlatform -> game_platform, 
      textGamePlatform -> text_game_platform */
      const labelName = convertUnderscoreCaseToSentenceCase(
        key.replace(/(filter_label|label)/, '').replace(/\[\]/g, ''),
      )
        // Remove spaces and square brackets
        .replace(/([[\] ])/g, '');

      result[`label${labelName.substring(0, 1).toUpperCase()}${labelName.substring(1)}`] =
        params[key];
    }
  });

  return result;
};
