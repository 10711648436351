import React from 'react';

import type { Deal } from '../../../types/Deal';
import getPriceType from '../../../utils/getPriceType';
import { DisplayPercentage } from '../DisplayPercentage';
import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';
import { HotDealFlag } from '../HotDealFlag';

import styles from './styles/main-price.css';

export interface MainPriceProps {
  deal: Deal;
  label?: string;
  className?: string;
}

const MainPrice: React.FC<MainPriceProps> = ({ deal, label, className: defaultClassName }) => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        showHotDeal,
        inFocus,
        buttonType,
        showWasPrice,
        percentageOffThreshold,
        genericSharedComponents: { DisplayPrice },
      } = value;

      let className = defaultClassName;
      // If the button type is chevron & a custom class name isn't provided, use the chevron class
      if (buttonType === 'chevron' && !className) {
        className = 'chevron';
      }

      const containerStyle = className && styles[className] ? className : 'container';

      return (
        <div className={styles[containerStyle]}>
          {showWasPrice === 'percentagePrice' && deal?.offer?.was_price && (
            <DisplayPercentage deal={deal} percentageOffThreshold={percentageOffThreshold} />
          )}
          <DisplayPrice deal={deal} className={className} type="wasPrice" />
          <div>
            <DisplayPrice
              deal={deal}
              label={label}
              className={className}
              type={getPriceType(deal)}
              showOfferLabel
            />
            {showHotDeal === '1' && inFocus && <HotDealFlag deal={deal} className="main-price" />}
          </div>
        </div>
      );
    }}
  </Consumer>
);

export default MainPrice;
