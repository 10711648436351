import { ActionTypes, UserState, UserStateAction } from '../types';

export const getInitialState = (): UserState => {
  return {
    user: null,
    sessionId: '',
    isSessionLoaded: false,
    signupForm: {
      error: '',
      isLoading: false,
      email: '',
      terms: false,
    },
  };
};

export const reducer = (state: UserState, action: UserStateAction): UserState => {
  switch (action.type) {
    case ActionTypes.GET_SESSION: {
      return {
        ...state,
        user: window.FUTR?.Kiosq?.User || null,
        sessionId: action.payload,
        isSessionLoaded: true,
        signupForm: {
          ...state.signupForm,
          isLoading: false,
        },
      };
    }
    case ActionTypes.SIGNUP_INIT: {
      return {
        ...state,
        signupForm: {
          ...state.signupForm,
          isLoading: true,
        },
      };
    }
    case ActionTypes.FORM_ERROR: {
      return {
        ...state,
        signupForm: {
          ...state.signupForm,
          error: action.payload.error,
          isLoading: false,
        },
      };
    }
    case ActionTypes.FORM_INPUT_CHANGE: {
      return {
        ...state,
        signupForm: {
          ...state.signupForm,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};
