import { getJSON } from '../../../assets/modules/ajax';
import type { Site } from '../../../core/modules/sites/types/Site';
import type { Deal } from '../../../core/types/Deal';
import type { RequestType } from '../../../core/types/GetJSON';
import { getRequestId } from '../../localStorage/localStorage';

export const httpFetchSearchApiRedeemUniqueCodePerDeal = async (
  endpoint: string,
  site: Site,
  area: string,
  deal: Deal,
): Promise<string> => {
  const redeemEndpoint = `${endpoint}/redeem.php`;
  const dataset = {
    site: site.id,
    area,
    request_id: getRequestId(),
    match_id: deal?.match_id,
  };
  const response = await getJSON<RequestType.REDEEM_REQUEST>(redeemEndpoint, dataset);
  const uniqueCodeObject = response.body;

  if (!uniqueCodeObject?.code) {
    throw new Error('No code returned from unique code fetch');
  }
  return uniqueCodeObject.code;
};
