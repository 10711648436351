/**
 * Get the url endpoint using the assets endpoint, or a relative url if this is not defined
 * The relative url is to support storybook
 */
export default (url: string): string => {
  if (process.env.ASSETS_ENDPOINT) {
    return `${process.env.ASSETS_ENDPOINT}/img/${url}`;
  }
  return `/img/${url}`;
};
