import React from 'react';

import type { IMasterWidgetMainProps } from './model';

// We copy props into child Component which would cause rerender
// This way we keep reference same and avoid rerender on useWidgetData hook rerender
// without state change
const MemoizedComponent: React.FC<{
  memoizedComponentProps: IMasterWidgetMainProps;
  // TODO: check this to remove any, doesnt seem to accept any other type than 'any'
}> = React.memo((props: any) => {
  const {
    memoizedComponentProps: { component: Component },
  } = props;

  return <>{Component && <Component {...props.memoizedComponentProps} />}</>;
});

MemoizedComponent.displayName = 'MemoizedMasterWidgetMain';

export default MemoizedComponent;
