import React, { useContext } from 'react';

import type { HawkWidgetProviderContext, HawkWidgetProviderInitialValue } from './model';

const initialValue: HawkWidgetProviderInitialValue = {};
export const Context = React.createContext<HawkWidgetProviderContext>(
  initialValue as HawkWidgetProviderContext,
);
export const { Provider } = Context;
export const { Consumer } = Context;
export const useHawkWidgetContext = (): HawkWidgetProviderContext => useContext(Context);
