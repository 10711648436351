const getAnyNodeWithDataset = (
  element: HTMLElement,
  field: string | null,
  count = 0,
): HTMLElement | null => {
  // Get the element that is a link & has a dataset
  // keep checking the parent until we find a matching element, with a maximum of 5 elements
  // to avoid setting an infinite loop
  const { dataset } = element;

  if (
    Object.keys(dataset).length > 0 &&
    (field === null || typeof dataset[field] !== 'undefined')
  ) {
    return element;
  }
  if (!(element.parentNode instanceof HTMLElement) || count >= 15) {
    return null;
  }

  return getAnyNodeWithDataset(element.parentNode, field, count + 1);
};

export default (element: HTMLElement, field: string | null = null): DOMStringMap => {
  const node = getAnyNodeWithDataset(element, field);

  if (node) {
    return node.dataset;
  }
  return {};
};
