import type { Widget } from '../../types/Widget';
import Localiser from '../Localiser';
import defaultSite from '../sites/data/defaultSite';

import APIFeatures from './APIFeatures';
import type Feature from './types/Feature';
import WidgetFeatures from './WidgetFeatures';

/**
 * Return the list of features
 */
const getAllFeatures = (): Feature[] => {
  const attributes: Record<string, string> = {};
  const widget: Widget = {} as Widget;
  const editorial = false;
  const localiser = new Localiser('en-US', 'US', defaultSite);
  const territory = '';
  const keywords = [];
  const widgetFeatures = new WidgetFeatures(
    attributes,
    widget,
    editorial,
    '',
    localiser,
    defaultSite,
    territory,
    keywords,
  );
  const apiFeatures = new APIFeatures(
    attributes,
    widget,
    editorial,
    '',
    localiser,
    defaultSite,
    territory,
    keywords,
  );
  return widgetFeatures.features.concat(apiFeatures.features);
};

export default getAllFeatures;
