import React from 'react';

import type { Deal } from '../../../types/Deal';

import styles from './styles/price-note.css';

interface PriceNoteProps {
  deal: Deal;
  className?: string;
}

const PriceNote: React.FC<PriceNoteProps> = ({ deal, className }) => {
  const containerStyle = className && styles[className] ? className : 'container';

  if (deal?.contract?.price_note) {
    return <span className={styles[containerStyle]}>{deal.contract.price_note}</span>;
  }
  return null;
};

export default PriceNote;
