export enum HawkEvents {
  HAWK_PROCESS_NOVA_WIDGET = 'HAWK_PROCESS_NOVA_WIDGET',
}

export interface WidgetData {
  type: string;
  id: string;
  excludeFrom?: string[];
  data: {
    linkText?: string;
    urlStyle?: string;
    url?: string;
    link?: {
      href: string;
      text: string;
    };
    image?: {
      id: string;
      src: string;
      width: 585;
      height: 585;
      credit: string;
      name: string;
    };
    text?: string;
    star?: string;
    starLabel?: string;
    layout?: string[] | { value: string }[];
    model?: {
      name?: string;
      brand?: string;
    };
    title?: string;
    price?: string;
    promo?: string[] | { value: string }[];
    matchId?: string;
    attributes?: Record<string, string>;
    hawk?: { attributes: Record<string, string> } | string;
    hawkSpecs?: string;
  };
}

export interface ProcessNovaWidgetEvent {
  el: HTMLElement;
  widgetData: WidgetData;
}
