import type { Deal } from '../types/Deal';
import type { Translate } from '../types/Localiser';

const getLinkText = (deal: Deal, translate: Translate): string => {
  // Use link text from the API if set, fallback to default link text (should never be needed)
  if (deal?.offer?.link_text) {
    return deal?.offer?.link_text;
  }
  return translate('defaultLinkText');
};

const getMerchantLinkText = (deal: Deal, translate: Translate): string => {
  // Use link text from the API if set, fallback to default link text (should never be needed)
  if (deal?.offer?.merchant_link_text) {
    return deal?.offer?.merchant_link_text;
  }
  const merchantName = deal?.merchant?.name;
  return translate('defaultMerchantLinkText', [merchantName ?? '']);
};

export default (deal: Deal, displayMerchant: boolean | undefined, translate: Translate): string => {
  const linkText = getLinkText(deal, translate);

  // If specified return the merchant name unless it is a fallback (product or constructed
  // offer using widget features)
  if (displayMerchant && deal?.product_type !== 300) {
    return getMerchantLinkText(deal, translate);
  }
  return linkText;
};
