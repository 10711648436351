import { getBuyingGuideStatus, getDealsPageStatus } from './keywords';

/**
 * Shared function to determine the vanilla article type (e.g. TRD)
 * This doesn't read the HTML so that it can be used client & server side
 */
export const getVanillaArticleType = (
  articleType: string | null,
  keywords: string[],
  urlPath: string,
): string => {
  keywords = keywords || [];

  // article type, keywords (type from fep, keywords from dfp_config OR sent from client to SS)
  // Article type "buying-guide" is not supported for all sites, so support both - HAWK-657
  if (getBuyingGuideStatus(keywords) || articleType === 'buying-guide') {
    return 'buying_guide';
  }
  if (getDealsPageStatus(keywords)) {
    return 'deals';
  }
  if (/\/deals\/compare.*$/.exec(urlPath)) {
    return 'deals_compare';
  }
  if (/\/shop.*$/.exec(urlPath)) {
    return 'shop';
  }
  /* Handle vouchers based on the url, as  the article type is only
  set for athe article and not the hub pages */
  // https://www.t3.com/discountcodes
  // https://www.realhomes.com/vouchercodes/argos
  // https://www.tomsguide.com.master.van-qa.futureplc.engineering/coupons
  // https://www.whowhatwear.com.master.van-qa.futureplc.engineering/promo-codes/category/activewear
  if (/\/(discountcodes|vouchercodes|coupons|promo-codes).*$/.exec(urlPath)) {
    return 'voucher';
  }

  switch (articleType) {
    case 'review':
    case 'award':
    case 'voucher':
      return articleType;
    case 'best':
    case 'best-pick':
    case 'news':
    case 'feature': // feature can't be relied upon (could be a gallery, news, buying guide and possibly more) so we consider it to be news
      return 'news';
    default:
      return 'hub';
  }
};
