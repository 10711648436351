import React from 'react';

import classNames from 'classnames';

import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/load-more.css';

export interface LoadMoreProps {
  className?: string | null;
}

const LoadMore: React.FC<LoadMoreProps> = ({ className }) => (
  <Consumer>
    {(value): JSX.Element | null => {
      const { loadMoreDeals, showFewerDeals, activeTab, translate, loadMore } = value;
      let { dealData } = value;
      dealData = dealData || {};
      const deals = (dealData.deals || []).length;

      // Don't display if we can't fill the default rows
      if (dealData.totalDeals > activeTab?.pageSize && loadMore) {
        const moreDeals = dealData.totalDeals > deals;
        const loadMoreText = moreDeals
          ? translate('reviewLoadMoreShowMore')
          : translate('reviewLoadMoreNoDeals');
        const containerStyle = className && styles[className] ? className : 'container';
        const loadedMore = deals > activeTab?.pageSize;

        switch (className) {
          case 'pocket':
            if (deals < dealData.totalDeals) {
              return (
                <div
                  className={styles.pocket}
                  onClick={(): void => loadMoreDeals(true)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(): void => loadMoreDeals(true)}
                >
                  <span className={styles.label}>{translate('reviewLoadMoreShowMore')}</span>
                </div>
              );
            }
            return null;
          default:
            return (
              <div
                className={classNames({
                  [styles[containerStyle]]: true,
                  [styles['more-deals-container']]: moreDeals,
                })}
              >
                <div
                  className={styles['load-more-container']}
                  onClick={(): void => {
                    if (moreDeals) {
                      loadMoreDeals(true);
                    }
                  }}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(): void => {
                    if (moreDeals) {
                      loadMoreDeals(true);
                    }
                  }}
                >
                  <span className={styles['load-more']}>{loadMoreText}</span>
                </div>
                <div className={styles['show-less-container']}>
                  {loadedMore ? (
                    <span
                      className={styles['show-less']}
                      onClick={showFewerDeals}
                      role="button"
                      tabIndex={0}
                      onKeyDown={showFewerDeals}
                    >
                      {translate('reviewLoadMoreShowLess')}
                    </span>
                  ) : null}
                </div>
              </div>
            );
        }
      }
      return null;
    }}
  </Consumer>
);

export default LoadMore;
