import he from 'he';

import { WidgetData } from '../model';

export const processDealWidgetPromo = (
  promo: WidgetData['data']['promo'],
): Record<string, string> => {
  if (promo) {
    // Handle the promo being either a string array or an object array
    const promoValues: string[] = promo.map((promoEntry: { value: string } | string) => {
      if (typeof promoEntry !== 'string') {
        return promoEntry.value;
      }

      return promoEntry;
    });

    if (promoValues.length > 0) {
      const result: string[] = promoValues.map((item) => {
        return JSON.stringify(item);
      });

      return { 'custom-promos': he.encode(`[${result.join(',')}]`) };
    }

    return {};
  }

  return {};
};
