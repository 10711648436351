import getImageUrl from '../../../../modules/getImageUrl';

import { ImgUrl } from './model';
import type { ImgParams } from './model';

export const updateImgSrc = (imgParams: ImgParams): ImgParams => {
  const { serversideImages, isServerside, isImgLazyLoadSupported } = imgParams;
  /**
   * Image src logic:
   * - Use the actual image if serversideImages is enabled and this is a serverside widget
   * - Use the actual image if native lazy loading is supported by the browser
   * and this is a client side widget
   * - Otherwise use the placeholder image
   */
  // Use the actual image serverside if serversideImages is enabled
  // or client side if native lazy loading is supported
  if ((isServerside && serversideImages) || isImgLazyLoadSupported) {
    return imgParams;
  }

  return {
    ...imgParams,
    src: getImageUrl(ImgUrl.NO_IMAGE),
  };
};
