import type { TabValues, Filters, SelectedFilters, SelectedFiltersTab } from './model';

interface GetNewSelectedFiltersParams {
  tabName: TabValues;
  tab: SelectedFiltersTab | undefined;
  filters: {
    params: Partial<Filters>;
    selected: Partial<Filters>;
  };
}

export const getNewSelectedFilters = ({
  tabName,
  tab,
  filters,
}: GetNewSelectedFiltersParams): SelectedFilters => {
  if (tab) {
    return {
      [tabName]: {
        ...tab,
        params: {
          ...tab.params,
          ...filters.params,
        },
        selected: {
          ...tab.selected,
          ...filters.selected,
        },
      },
    };
  }

  return {};
};
