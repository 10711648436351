import Feature from '../../types/Feature';

const getDefaultValue = <T>(feature: Feature): T | null => {
  if (feature.getDefaultValue) {
    // TODO rework in future(this function is not currently generic)
    return feature.getDefaultValue() as T;
  }
  return null;
};

export default getDefaultValue;
