import React from 'react';

import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import MainToggle from './MainToggle';
import styles from './styles/main.css';

export interface MainProps {
  className?: string;
  showToggle?: boolean;
  children?: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children, className, showToggle }) => {
  const { editorial, name } = useHawkWidgetContext();
  const isOpen = typeof window !== 'undefined' ? window.innerWidth > 575 : false;
  const [toggleOpen, setToggleOpen] = React.useState(isOpen);

  const toggleDisplay = (): void => {
    setToggleOpen((prevValue) => !prevValue);
  };

  const containerClassName = ((): string => {
    if (editorial || name === 'BOT widget') {
      return 'editorial-container';
    }
    if (className && styles[className]) {
      return className;
    }
    return 'container';
  })();
  // Show the widget and if we are showing a toggle, only show the widget if the toggle is open
  const showWidget = showToggle ? toggleOpen : true;

  return (
    <div className={styles[containerClassName]}>
      {showToggle && (
        <MainToggle
          {...{
            toggleOpen,
            toggleDisplay,
          }}
        />
      )}
      {showWidget && children}
    </div>
  );
};

export default Main;
