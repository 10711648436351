import React from 'react';

import StarRatingStar from './StarRatingStar';
import styles from './styles/star-rating.css';

interface StarRatingProps {
  score: number;
  className?: string;
  rating?: string;
}

const StarRating: React.FC<StarRatingProps> = (props) => {
  const { score, className, rating } = props;
  const containerStyle = className && styles[className] ? className : 'container';
  const stars: JSX.Element[] = [];
  for (let i = 0; i < Math.floor(score / 2); i++) {
    stars.push(<StarRatingStar className={className} rating={rating} key={`star-${i}`} />);
  }
  if (score % 2 !== 0) {
    stars.push(
      <StarRatingStar className={className} rating={rating} type="half" key="star-half" />,
    );
  }
  while (stars.length < 5) {
    stars.push(
      <StarRatingStar
        className={className}
        rating={rating}
        type="empty"
        key={`star-empty-${stars.length}`}
      />,
    );
  }

  return <div className={styles[containerStyle]}>{stars}</div>;
};

export default StarRating;
