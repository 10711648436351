import getViewports from './getViewports';

// Setup data-viewports for the JS based width mixins (e.g. small container)
// Only do this for rendered widgets to minimise impact on page performance
export const setDataViewports = (el: HTMLElement | null): void => {
  let width = el?.offsetWidth ?? 0;
  const setViewport = (): void => {
    // Set data-viewport so we show mobile elements in narrow containers
    width = el?.offsetWidth ?? 0;
    el?.setAttribute('data-viewports', getViewports(width));
  };
  // Check when the window is resized
  // We want to update data-viewports when the window size changes (i.e. the element changes size)
  window.addEventListener('resize', setViewport);
  setViewport();
};
