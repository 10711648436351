import React from 'react';

import { observe } from 'react-intersection-observer';

import getUrlParameters from '../../../utils/getUrlParameters';
import {
  useAnalytics,
  postProcessWidget,
  WidgetResult,
  ApiCallResult,
} from '../../hooks/useAnalytics';
import { WidgetId } from '../../types/Widget';
import { SiteWrapper } from '../SiteWrapper';

import Fallback from './Fallback';
import { genericSharedComponents } from './genericSharedComponents';
import MemoizedComponent from './MemoizedComponent';
import type { IMasterWidgetMainProps } from './model';
import styles from './styles/master-widget.css';

const MasterWidgetMain: React.FC<IMasterWidgetMainProps> = (props): JSX.Element => {
  const {
    placeholder,
    type,
    widgetId,
    widgetTypeComponent,
    data,
    apiCall,
    dealData,
    renderFallback: defaultRenderFallback,
    site,
    widgetWrapper,
  } = props;
  const { inFocusDuration, inFocusParam } = getUrlParameters(['inFocusDuration', 'inFocus']);

  const {
    analytics,
    onViewportLeave,
    onViewportEnter,
    setDealData,
    sendAnalytics,
    getAnalyticsData,
    modifiedGetDealData,
    inFocus,
  } = useAnalytics({
    ...props,
    inFocus: inFocusParam ? Boolean(inFocusParam) : false,
    inFocusDuration: inFocusDuration || 8000,
  });

  const memoizedComponentProps = React.useMemo(
    () => ({
      ...props,
      inFocus,
      getDealData: modifiedGetDealData,
      sendAnalytics,
      setDealData,
      getAnalyticsData,
      genericSharedComponents: {
        ...genericSharedComponents,
      },
    }),
    [props, modifiedGetDealData, inFocus],
  );

  React.useEffect(() => {
    postProcessWidget({
      placeholder,
      type,
      widgetId,
      sendAnalytics,
      setDealData,
      widgetTypeComponent,
      sendViewedEvent: analytics.current.sendViewedEvent,
      dealData,
      data,
    });

    // if there is placeholder observe its entrance into viewport
    const destroyObserver =
      placeholder &&
      observe(placeholder, (inView: boolean) => (inView ? onViewportEnter() : onViewportLeave()));

    return () => {
      if (destroyObserver) {
        destroyObserver();
      }
    };
    // We want to run this hook only once, so we omit dep list
  }, []);

  const renderServerSideHtml = apiCall === ApiCallResult.FAILURE || type === WidgetResult.SKIPPED;

  const renderFallback =
    defaultRenderFallback ??
    (type === WidgetResult.MISSING && widgetTypeComponent !== WidgetId.PROMOTION_FALLBACK);

  /*
   ** Wrap hawk with div or span based on widget
   ** so React has anchor to render into (https://github.com/vercel/next.js/discussions/37631)
   */
  return React.createElement(
    widgetWrapper,
    { className: styles['hawk-base-wrapper'] },
    <SiteWrapper site={site}>
      {renderFallback || renderServerSideHtml ? (
        <Fallback {...props} renderServerSideHtml={renderServerSideHtml} apiCall={apiCall} />
      ) : (
        <MemoizedComponent memoizedComponentProps={memoizedComponentProps} />
      )}
    </SiteWrapper>,
  );
};

export default MasterWidgetMain;
