import React from 'react';

import {
  apstagAttributes,
  getAmazonApstagAttributesForHref,
  SupportedMerchants,
} from '../../../../sharedModules/apstag/getAttributes';
import { isFullUrl } from '../../../utils/isFullUrl';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import type { TrackedLinkProps } from './model';

export enum TargetEnum {
  SELF = '_self',
  BLANK = '_blank',
  PARENT = '_parent',
  TOP = '_top',
}

const TrackedLink: React.FC<TrackedLinkProps> = (props) => {
  const middleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    const { trackLinkClick } = props;
    event.stopPropagation();
    // Track the click if it is a middle click
    // middle clicks don't fire onClick or onContextMenu events
    if (typeof event.button !== 'undefined' && event.button === 1 && trackLinkClick) {
      trackLinkClick(event);
    }
  };

  const click = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    const { trackLinkClick } = props;
    event.stopPropagation();
    // Track the click if it is not a middle click
    // middle clicks don't fire onClick or onContextMenu events
    // Added to ensure Firefox doesn't fire both click events
    if (typeof event.button !== 'undefined' && event.button !== 1 && trackLinkClick) {
      trackLinkClick(event);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>): void => {
    const { trackLinkClick } = props;
    event.stopPropagation();

    if (typeof event.key !== 'undefined' && event.code === 'Enter' && trackLinkClick) {
      trackLinkClick(event);
    }
  };

  const getTarget = (siteHostname: string, linkUrl?: string): string => {
    if (linkUrl && isFullUrl(linkUrl)) {
      try {
        const link = new URL(linkUrl);
        const linkHostname = link.hostname.startsWith('www.')
          ? link.hostname.slice(4)
          : link.hostname;

        return siteHostname === linkHostname ? TargetEnum.SELF : TargetEnum.BLANK;
      } catch {
        return TargetEnum.BLANK;
      }
    }
    // Its not url, just path (editors use path to link on the same site)
    return TargetEnum.SELF;
  };

  const { children, customTarget } = props;
  let { attributes } = props;
  const { translate, site } = useHawkWidgetContext();

  // don't add 'sponsored noopener' to internal links
  if (site?.url && attributes?.href && getTarget(site.url, attributes.href) === TargetEnum.BLANK) {
    attributes = {
      ...attributes,
      rel: 'sponsored noopener',
    };
  }

  // We have to check that the merchant link includes merchant Amazon,
  // as some widgets have the merchant Amazon US and some Amazon, both for
  // Amazon in the US
  if (
    attributes?.href &&
    attributes?.['data-link-merchant'] &&
    SupportedMerchants.filter((merchant) =>
      attributes['data-link-merchant']?.toLowerCase()?.includes(merchant),
    ).length > 0
  ) {
    const hrefAttributes = getAmazonApstagAttributesForHref(attributes.href);
    // We trust the data from the API more than what we harvest from the url
    // but only if the API asin starts with B0 showing that it has an asin structure
    // and we have a tag and subtag, if we don't have the tag/subtag we want to overwrite
    // the attributes from API to remove the asin
    if (
      attributes[apstagAttributes.asin]?.startsWith('B0') &&
      hrefAttributes[apstagAttributes.tag] &&
      hrefAttributes[apstagAttributes.ascsubtag]
    ) {
      attributes = {
        ...hrefAttributes,
        ...attributes,
      };
    } else {
      attributes = {
        ...attributes,
        ...hrefAttributes,
      };
    }
  }

  return (
    <a
      onClick={click}
      onContextMenu={click}
      onMouseDown={middleClick}
      data-google-interstitial="false"
      aria-label={translate('defaultLinkText')}
      {...(attributes ?? {})}
      target={customTarget ?? getTarget(site?.url, attributes.href)}
      onKeyDown={onKeyDown}
      role="link"
      tabIndex={0}
    >
      {children}
    </a>
  );
};

export default TrackedLink;
