import type { Deal, Promo } from '../types/Deal';

const flags = [
  'black-friday',
  'cyber-monday',
  'seasonal',
  'recommended',
  'popular',
  'exclusive',
  'prime',
  'editors-pick',
  'limited-stock',
  'was-price',
  'was-price-light',
];

const blacklist = ['dotw'];

/**
 * Return promos that are considered as being flags
 */
export const getFlagPromos = (deal: Deal | Partial<Deal>): Promo[] => {
  if (deal) {
    return (deal.promos || []).filter((promo) => flags.indexOf(promo.type) >= 0);
  }
  return [];
};

/**
 * Return promos that are NOT considered as being flags (e.g. deal text) or that are blacklisted
 */
export const getNonFlagPromos = (deal: Deal | Partial<Deal>): Promo[] => {
  if (deal) {
    return (deal.promos || []).filter(
      (promo) => flags.indexOf(promo.type) === -1 && blacklist.indexOf(promo.type) === -1,
    );
  }
  return [];
};

export const getAllowedPromos = (deal: Deal | Partial<Deal>): Promo[] => {
  if (deal) {
    return (deal.promos || []).filter((promo) => blacklist.indexOf(promo.type) === -1);
  }
  return [];
};

export const getPromo = (deal: Deal | Partial<Deal>, type: string): Promo[] => {
  if (deal) {
    return (deal.promos || []).filter((promo) => promo.type === type).slice(0, 1);
  }
  return [];
};

export const getPromos = (deal: Deal | Partial<Deal>, type: string): Promo[] => {
  const promos = ((): Promo[] => {
    switch (type) {
      case 'allowed':
        return getAllowedPromos(deal);
      case 'flags':
        return getFlagPromos(deal);
      case 'nonFlags':
        return getNonFlagPromos(deal);
      default:
        return getPromo(deal, type);
    }
  })();

  return promos.map((promo) => {
    return {
      type: promo.type,
      value: promo.display_value,
      display_value:
        promo.display_value && promo.display_value.length > 18
          ? `${promo.display_value.substring(0, 18)}...`
          : promo.display_value,
    };
  });
};
