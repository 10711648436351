import getElements from './getElements';

// This is essentially a wrapper for querySelectorAll, but returns the elementsas an array,
// rather than NodeList so you can use all the Array prototype methods. Also it should make
// our code a bit smaller and cleaner, so for example this:
//
//      Array.prototype.slice.call(document.querySelectorAll("th.sortable"))
//        .forEach(function(element){/* code goes here */});
//
// becomes:
//
//      HawkUtil.get("th.sortable").forEach(function(element){/* code goes here */});
//
// Which I think is a bit more readable and less scary
export const getNodes = (options: {
  selector: string;
  context?: HTMLElement | Document;
}): HTMLElement[] => {
  const { selector } = options;
  let { context } = options;

  // The context is the element to search inside, if undefined, we should use document
  if (
    (typeof context === 'undefined' || typeof context.querySelectorAll !== 'function') &&
    typeof document !== 'undefined'
  ) {
    context = document;
  }
  return getElements(selector, context);
};
