import { Filter } from '../../../../types/Filter';

export const getDataFilters = (oldFilters: Filter[], newFilters: Filter[]): Filter[] => {
  // Update the filter counts if we already have filters (the tab was previously loaded)
  if (oldFilters && oldFilters.length > 0) {
    return oldFilters.map((oldFilter) => {
      const newFilter = newFilters.find(
        (newFilter) => newFilter.filter_key === oldFilter.filter_key,
      );
      return {
        ...oldFilter,
        values: oldFilter.values.map((oldFilterValue) => {
          // Update the count if the value was found, otherwise set it as 0
          if (newFilter) {
            const newValue = newFilter.values.find(
              (newFilterValue) => newFilterValue.value === oldFilterValue.value,
            );
            return {
              ...oldFilterValue,
              count: newValue ? newValue.count : 0,
            };
          }
          return {
            ...oldFilterValue,
            count: 0,
          };
        }),
      };
    });
  }

  // In case there are no filters (e.g. new tab), just use what we got from the API
  return newFilters;
};
