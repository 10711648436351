import hasValue from './hasValue';

const getValue = <T, S>(defaultValue: T, attributeValue?: S): T | S | null => {
  if (hasValue(attributeValue) && typeof attributeValue === 'string') {
    return attributeValue;
  }
  if (hasValue(defaultValue)) {
    return defaultValue;
  }
  return null;
};

export default getValue;
