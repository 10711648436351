import { WidgetData } from './model';

export const readHawkData = (widgetData: WidgetData): Record<string, string> => {
  if (widgetData.data?.hawk) {
    if (typeof widgetData.data.hawk === 'string') {
      // We want to avoid crashing js in browser
      try {
        return JSON.parse(widgetData.data?.hawk);
      } catch (_) {
        return {};
      }
    }

    // We want to ensure that attributes are object as we cannot operate on different structures anyway
    if (
      widgetData.data.hawk.attributes &&
      typeof widgetData.data.hawk.attributes === 'object' &&
      widgetData.data.hawk.attributes !== null
    ) {
      return widgetData.data.hawk.attributes;
    }

    return {};
  }

  if (widgetData.data?.hawkSpecs) {
    // We want to avoid crashing js in browser
    try {
      return JSON.parse(widgetData.data.hawkSpecs);
    } catch (_) {
      return {};
    }
  }

  return {};
};
