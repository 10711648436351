import getUrlParameters from '../../utils/getUrlParameters';

export default (property: string): string | null => {
  if (property) {
    const { article_id: articleId } = getUrlParameters();
    if (articleId) {
      return articleId;
    }

    if (typeof window !== 'undefined' && window?.dfp_config?.article_id) {
      return window.dfp_config.article_id;
    }

    // Ensure analytics ga data is setup
    if (!window.analytics_ga_data) {
      window.analytics_ga_data = {};
    }
    const gaData = window.analytics_ga_data || {};
    return gaData[property];
  }
  return null;
};
