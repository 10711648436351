import React from 'react';

import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import getSiteLogo from './getSiteLogo';

interface Props {
  className?: string;
}

const SiteLogo = ({ className }: Props): JSX.Element | null => (
  <Consumer>
    {(value): JSX.Element | null => {
      const {
        site,
        genericSharedComponents: { LazyImage },
      } = value;
      const imageUrl = getSiteLogo(site);

      if (imageUrl) {
        return (
          <LazyImage
            {...{
              src: imageUrl,
              alt: site && site.label,
              className: `site-logo-${className}`,
              width: 60,
              height: 30,
            }}
          />
        );
      }
      return null;
    }}
  </Consumer>
);

export default SiteLogo;
