import getRenderType from '../../utils/getRenderType';

import { getNodes } from './getNodes';

interface Config {
  classNames?: string[];
  dataset: Record<string, any>;
  node: HTMLElement | Element | null;
  position: string;
  renderType?: string;
  tagName?: string;
  wrapNode?: boolean;
}

const getElement = async (config: Config): Promise<HTMLElement | null> => {
  // Find the first matching selector
  const { node, tagName, classNames, renderType } = config;
  let { dataset } = config;

  /* Create an element - but only if a node was found
  Also don't add the element if the widget was already addedserverside - checking
  the name and model to cover awards etc. */
  const serversideWidgets = await (async (): Promise<HTMLElement[]> => {
    if (dataset && dataset.name && dataset.modelName) {
      const nodes = getNodes({
        selector: `.hawk-nest[data-name="${dataset.name}"][data-model-name="${dataset.modelName}"]`,
      });
      return nodes;
    }
    return [];
  })();
  if (node && dataset && serversideWidgets.length === 0) {
    const element = document.createElement(tagName || 'div');
    // Add class names to the element
    (classNames || []).concat(['hawk-nest']).forEach((className) => {
      element.classList.add(className);
    });
    dataset = {
      ...dataset,
      renderType: getRenderType(renderType || '', 'dynamic'),
    };
    // Add dataset values to the element
    Object.keys(dataset).forEach((key) => {
      let newKey = key.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);
      // Replace '_' with '-' - supports old format (this is still sent for view all deals links)
      newKey = newKey.replace('_', '-');
      newKey = `data-${newKey}`;
      // Fix for [] - e.g. filter_label[colour] - replaces '[' with - removes ']'
      let attrName = newKey.replace('[', '-');
      attrName = attrName.replace(/]/g, '');
      element.setAttribute(attrName, dataset[key]);
    });

    return element;
  }
  return null;
};

export default async (config: Config): Promise<HTMLElement | null> => {
  // Store the created placeholder and their locations
  // Add them to the page at the end - do all writes to the DOM at the same time
  const { node, position, wrapNode } = config;
  const element = await getElement(config);

  if (element && node) {
    // Add the placeholder in the specified position
    if (position === 'end') {
      node.appendChild(element);
    } else if (position === 'before' && node.parentNode) {
      node.parentNode.insertBefore(element, node);
    } else if (position === 'start') {
      node.insertBefore(element, node.firstChild);
    } else if (position === 'replace') {
      node.innerHTML = '';
      node.appendChild(element);
    } else if (node.parentNode) {
      node.parentNode.insertBefore(element, node.nextSibling);
    }

    /* Add the node as the child for the widget placeholder - added so
    we can wrap images with link widgets in buying guides */
    if (wrapNode) {
      element.appendChild(node);
    }

    return element;
  }
  return null;
};
