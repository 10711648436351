import { postJSON } from '../../../assets/modules/ajax';
import type { Site } from '../../../core/modules/sites/types/Site';
import { RequestType } from '../../../core/types/PostJSON';

export const httpPostRewardActivityProcessorUserReward = async (
  userId: string,
  email: string,
  site: Site,
  sessionId: string,
  productId: string,
): Promise<void> => {
  const endpoint = process.env.REWARD_ACTIVITY_PROCESSOR_ENDPOINT || '';
  const dataset = {
    userId,
    email,
    productId,
    sessionId,
    siteCode: site.vanillaName || site.name,
  };
  postJSON<RequestType.UPDATE_USER_REWARD_REQUEST>(endpoint, dataset);
};
