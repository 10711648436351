import { createTimeOutedInterval } from '../../../../../../../../sharedModules/createTimeOutedInterval/createTimeOutedInterval';

export const isKiosqUserUpdated = (): Promise<boolean> => {
  return new Promise<boolean>((resolve) => {
    const isKiosqUserAvailable = (): boolean => {
      return !!window?.FUTR?.Kiosq?.User;
    };
    const successCallback = (): void => {
      resolve(true);
    };
    const timeoutCallback = (): void => {
      resolve(false);
    };

    createTimeOutedInterval(isKiosqUserAvailable, successCallback, timeoutCallback);
  });
};
