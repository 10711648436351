import type Localiser from '../../../../core/modules/Localiser';
import type { Site } from '../../../../core/modules/sites/types/Site';
import type { PlatformData } from '../../../../core/types/PlatformData';
import { HawkEvents } from '../../../../types/HawkEvents';
import { getNodes } from '../../getNodes';

import { resolveWidgets } from './resolveWidgets';

interface ArticleConfig {
  siteConfig: Site;
  localiser: Localiser;
  editorialOnPage: boolean;
  platformData: PlatformData;
}

interface ProcessInfiniteArticleEvent {
  element: HTMLElement;
}

/**
 *  Watch for new hawk-nests in articles, used mainly in infinite scroll articles
 *  https://www.windowscentral.com/gaming/nacon-launch-rig-600-pro-dual-wireless-gaming-headset
 */
export const handleInfiniteScrollArticles = (config: ArticleConfig): void => {
  const { siteConfig, localiser, editorialOnPage, platformData } = config;
  document.addEventListener(
    HawkEvents.HAWK_PROCESS_ARTICLE,
    async (event: CustomEventInit<ProcessInfiniteArticleEvent>) => {
      if (event.detail?.element) {
        const unprocessedWidgets: Array<HTMLElement | null> = getNodes({
          selector: '.hawk-nest,.hawk-placeholder',
          context: event.detail.element,
        });
        resolveWidgets({
          unprocessedWidgets,
          siteConfig,
          localiser,
          editorialOnPage,
          platformData,
        });
      }
    },
  );
};
