export const getStrongText = (anchorNode: HTMLAnchorElement | null): string => {
  // Editorial mark up some deals block like so
  // <strong>Awesome deal</strong><a> was $311, now $299</a>
  // We now need to get both DOM nodes and concatenate them.
  if (
    anchorNode !== null &&
    anchorNode.previousSibling !== null &&
    anchorNode.previousSibling.nodeName === 'strong'
  ) {
    const strongNodeText = anchorNode.previousSibling.firstChild?.textContent; // First child should be a text node
    return `${strongNodeText || ''} `;
  }

  return '';
};
