import React from 'react';

import { ActionTypes } from './model';
import type { Action, Actions } from './model';

export const getActions = (dispatch: React.Dispatch<Action>): Actions => {
  const setCurrentPage: Actions['setCurrentPage'] = (currentPage) => {
    dispatch({ type: ActionTypes.SET_CURRENT_PAGE, payload: currentPage });
  };
  const setCurrentSwipeX: Actions['setCurrentSwipeX'] = (currentSwipeX) => {
    dispatch({ type: ActionTypes.SET_CURRENT_SWIPE_X, payload: currentSwipeX });
  };
  const setCurrentStop: Actions['setCurrentStop'] = (currentStop) => {
    dispatch({ type: ActionTypes.SET_CURRENT_STOP, payload: currentStop });
  };
  return React.useMemo<Actions>(
    () => ({
      setCurrentPage,
      setCurrentSwipeX,
      setCurrentStop,
    }),
    [],
  );
};
