import type { Image } from '../../../../types/Image';

export default (images: Image[]): string => {
  let result = '';

  images.forEach((image) => {
    if (image.src) {
      const separator = result === '' ? '' : ', ';
      result = `${result}${separator}${image.src} ${image.size}w`;
    }
  });

  return result;
};
