import React from 'react';

import classNames from 'classnames';

import type { Deal } from '../../../types/Deal';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import GridItem from './GridItem';
import { GridItemsClassName } from './model';
import NoDeals from './NoDeals';
import styles from './styles/grid-items.css';

export interface GridItemsProps {
  className: GridItemsClassName;
  reverse?: boolean;
  stacked?: boolean;
  children: (deal: Deal, index?: number) => JSX.Element;
  expandedChildren?: (deal: Deal, index?: number) => JSX.Element;
  showSpacer?: boolean;
  promosPosition?: string;
  animation?: string;
  // Remove after migration of Promos,DealCountdown
  widgetName: string;
}

const GridItems: React.FC<GridItemsProps> = (props) => {
  const {
    className,
    reverse,
    stacked,
    children,
    expandedChildren,
    showSpacer,
    animation,
    widgetName,
  } = props;
  let { promosPosition } = props;
  const {
    countdownPosition,
    // showShipping,
    promosPosition: defaultPromosPosition,
    genericSharedComponents: { Animate },
  } = useHawkWidgetContext();
  let { dealData, showPromos } = useHawkWidgetContext();
  dealData = dealData || {};
  const deals = dealData.deals || [];
  promosPosition = promosPosition || defaultPromosPosition;
  showPromos = showPromos && promosPosition && promosPosition !== 'disabled' ? promosPosition : '';
  const hasPromos = Boolean(deals.find((d) => (d.promos || []).length > 0));
  const showCountdown = countdownPosition || 'below';

  // getBlocks is required so we actually build some items
  if (children) {
    return (
      <div
        className={classNames(styles['container'], className.gridItems)}
        data-stacked={stacked ? 1 : 0}
      >
        <Animate reverse={reverse} animation={animation}>
          {((): JSX.Element | JSX.Element[] => {
            if (deals.length === 0) {
              return <NoDeals />;
            }

            return deals.map(
              (deal: Deal, index: number): JSX.Element => (
                <GridItem
                  deal={deal}
                  index={index}
                  className={className}
                  stacked={stacked}
                  expandedChildren={expandedChildren}
                  showSpacer={showSpacer}
                  showPromos={((): string => {
                    /* If stacked, ensure we have the same showPromos status for all items to
                  avoid misaligned columns */
                    if ((stacked && hasPromos) || (deal?.promos && deal.promos?.length > 0)) {
                      return showPromos;
                    }

                    return '';
                  })()}
                  showCountdown={showCountdown}
                  key={`${deal?.id}-${deal?.merchant.name}`}
                  widgetName={widgetName}
                >
                  {children}
                </GridItem>
              ),
            );
          })()}
        </Animate>
      </div>
    );
  }
  return null;
};

export default GridItems;
