/**
 * Determine if a buying guide - true if words 'buying' and 'guide'
 * exist in a keyword (case insensitive)
 */
export const getBuyingGuideStatus = (keywords: string[]): string | void => {
  keywords = keywords || [];
  return keywords.find(
    (keyword) => keyword.search(/buying/gi) >= 0 && keyword.search(/guide/gi) >= 0,
  );
};

/**
 * Determine if a deals page - true if 'deals' is any of the keywords (case insensitive)
 */
export const getDealsPageStatus = (keywords: string[]): string | void => {
  keywords = keywords || [];
  return keywords.find((keyword) => {
    return keyword.toLowerCase() === 'deals';
  });
};
