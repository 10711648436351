import React from 'react';

import styles from './styles/star-rating-star.css';

interface StarRatingStarProps {
  className?: string;
  type?: string;
  rating?: string;
}

const StarRatingStar: React.FC<StarRatingStarProps> = ({ className, type, rating }) => {
  const containerClassName = className && styles[className] ? className : 'container';

  // Useful reference http://jsfiddle.net/M8UAy/
  // This requires the star character as span text content
  return (
    <span className={styles[containerClassName]} data-type={type} data-rating={rating}>
      ☆
    </span>
  );
};

export default StarRatingStar;
