import React from 'react';

import { CSSTransition } from 'react-transition-group';

import AnimateWrapper from './AnimateWrapper';
import styles from './styles/animate.css';

export interface AnimateProps {
  reverse?: boolean;
  animation?: string;
  children?: React.ReactNode;
}

const Animate: React.FC<AnimateProps> = ({ children, reverse, animation: defaultAnimation }) => {
  const animation = defaultAnimation || 'slide';

  if (children) {
    const getClassName = (type): string | null => {
      if (reverse && styles[`${animation}-${type}-reverse`]) {
        return styles[`${animation}-${type}-reverse`];
      }
      if (styles[`${animation}-${type}`]) {
        return styles[`${animation}-${type}`];
      }
      return null;
    };
    const enter = getClassName('enter');
    const enterActive = getClassName('enter-active');
    const leave = getClassName('leave');

    return (
      <CSSTransition
        {...{
          classNames: {
            enter,
            enterActive,
            leave,
          },
          timeout: 500,
        }}
      >
        <AnimateWrapper>{children}</AnimateWrapper>
      </CSSTransition>
    );
  }
  return null;
};

export default Animate;
