export const getTabsContainerStyle = (
  isSpecificNamedTab: boolean,
  styles: NodeModule,
  className?: string,
): string => {
  if (isSpecificNamedTab) {
    return 'specific-named-tab';
  }
  if (className && styles[className]) {
    return className;
  }
  return 'container';
};
