import React from 'react';

import { WidgetId } from '../../../types/Widget';
import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/no-deals.css';

const NoDeals: React.FC = () => {
  const { loading, translate, widget } = useHawkWidgetContext();
  const message = ((): string => {
    if (widget.id === WidgetId.COMPARISON) {
      if (loading) {
        return translate('comparisonNoDealsLoading', ['<strong>', '</strong>']);
      }
      return translate('comparisonNoDealsLoaded', ['<strong>', '</strong>']);
    }
    return translate('reviewNoDealsLabel');
  })();
  const className = styles[widget.id] ? widget.id : 'container';

  return (
    <div className={styles[className]}>
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      />
    </div>
  );
};

export default NoDeals;
