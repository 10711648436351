import React, { useState } from 'react';

import { ClickType } from '../../../../../sharedModules/getClickType';
import type { Event } from '../../../../../types/Analytics';
import { FreyrEventPrefix } from '../../../../../types/FreyrEventPrefix';
import type { Deal } from '../../../../types/Deal';
import { useHawkWidgetContext } from '../../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/terms-conditions.css';

interface VultureTermsConditionsProps {
  deal: Deal;
}

export const VultureTermsConditions: React.FC<VultureTermsConditionsProps> = ({ deal }) => {
  const [closed, setVisibility] = useState(true);
  const { translate, sendAnalytics } = useHawkWidgetContext();

  const onClick = (): void => {
    setVisibility((prevValue) => !prevValue);

    if (sendAnalytics) {
      sendAnalytics({
        // Currently there is no click type for toggling terms and conditions
        clickType: ClickType.INTERNAL,
        prefix: FreyrEventPrefix.CLICK_FROM,
        label: closed ? 'Opened Terms and Conditions' : 'Closed Terms and conditions',
      } as Event);
    }
  };

  return (
    <>
      <div
        className={styles.container}
        data-google-interstitial="false"
        onClick={onClick}
        role="button"
        tabIndex={0}
        onKeyDown={onClick}
      >
        <span className={styles['terms-text']}>{translate('voucherTermsConditions')}</span>
        <span className={styles['terms-chevron']}>
          {closed ? <span>&#x25BC;</span> : <span>&#x25B2;</span>}
        </span>
      </div>
      <div className={styles[closed ? 'hidden-description' : 'description']}>
        {deal?.offer?.product_note}
      </div>
    </>
  );
};
