import { getHawk, updateHawk } from '../../../utils/global';

export const cacheValue = (key: string, value: string | number | null): void => {
  updateHawk({
    gaCache: {
      ...(getHawk().gaCache || {}),
      [key]: value,
    },
  });
};
