export default <T>(items: T[] = [], itemsPerRow = 4): T[][] => {
  // Format the items into separate arrays based on itemsPerRow
  const arr = items.slice(0);
  const formatted: T[][] = [];
  const startIndex = 0;
  let remaining = items.length;

  while (remaining > 0) {
    if (arr.length >= itemsPerRow) {
      const rowDeals = arr.splice(startIndex, itemsPerRow);
      formatted.push(rowDeals);
      remaining -= itemsPerRow;
    } else {
      const rowDeals = arr.splice(startIndex, arr.length);
      formatted.push(rowDeals);
      remaining = 0;
    }
  }

  return formatted;
};
