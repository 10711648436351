import { Tab } from '../../../../types/Tab';

export const getTabContainerStyle = (
  styles: NodeModule,
  tab: Tab,
  isActive: boolean,
  isSpecificNamedTab: boolean,
  className?: string,
): string => {
  if (isActive && isSpecificNamedTab && styles['specific-named-tab-active']) {
    return 'specific-named-tab-active';
  }
  if (isSpecificNamedTab && styles['specific-named-tab']) {
    return 'specific-named-tab';
  }
  if (isActive && styles[`${tab.value}-${tab.type}-active`]) {
    return `${tab.value}-${tab.type}-active`;
  }
  if (styles[`${tab.value}-${tab.type}`]) {
    return `${tab.value}-${tab.type}`;
  }
  if (isActive && styles[`${tab.value}-active`]) {
    return `${tab.value}-active`;
  }
  if (isActive && styles[`${className}-active`]) {
    return `${className}-active`;
  }
  if (isActive) {
    return 'active';
  }
  if (styles[tab.value]) {
    return tab.value;
  }
  if (className && styles[className]) {
    return className;
  }
  return 'container';
};
