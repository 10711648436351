import React from 'react';

import { WidgetId } from '../../types/Widget';

import type { IMasterWidgetMainProps } from './model';

const Fallback = ({
  widgetTypeComponent,
  linkText,
  renderServerSideHtml,
  serverSideHtml,
}: Partial<IMasterWidgetMainProps> & {
  renderServerSideHtml?: boolean;
  linkText?: string;
}): JSX.Element | null => {
  if (widgetTypeComponent === WidgetId.LINK && linkText) {
    return <>{linkText}</>;
  }

  if (renderServerSideHtml && serverSideHtml) {
    // We can disable this warning because we are sure its sanitized HTML
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: serverSideHtml }} />;
  }

  return null;
};

export default Fallback;
