import type { Deal } from '../types/Deal';
import type { Translate } from '../types/Localiser';
import productAmount from '../utils/productAmount';

export default (deal: Deal, translate: Translate): string => {
  if (deal?.contract) {
    if (deal.contract.length === 1) {
      return translate('contractLengthMonth', [String(deal.contract.length)]);
    }
    if (deal.contract.length === 999999) {
      return translate('contractLengthLifetime');
    }
    if (deal.contract.length && deal.contract.length > 1) {
      return translate('contractLengthMonths', [
        String(productAmount(deal.contract.length, translate)),
      ]);
    }
    if (deal.contract.length === 0) {
      return translate('contractLengthNone');
    }
  }
  return '';
};
