import flowRight from 'lodash.flowright';

import { addWebpExt } from './addWebpExt';
import { getResizedImageUrl } from './getResizedImageUrl';

export const processDealWidgetImage = (imageSrc?: string): Record<string, string> => {
  if (imageSrc) {
    const imageUrl: string = flowRight(
      addWebpExt,
      getResizedImageUrl,
    )(imageSrc.replace('http', 'https'));

    return {
      image: imageUrl.replace('.webp', ''),
      ...(imageUrl.includes('.webp') ? { 'webp-image': imageUrl } : {}),
    };
  }

  return {};
};
