export const getResizedImageUrl = (imageUrl: string): string => {
  if (imageUrl.includes('/-d+-d+/')) {
    return imageUrl.replace('/-d+-d+/', '-200-100');
  }

  const extension = imageUrl.match(/\.[^/.]+$/);
  if (extension) {
    return imageUrl.replace(extension[0], `-200-100${extension[0]}`);
  }

  return imageUrl;
};
