import getAPIEndpoint from '../../utils/getAPIEndpoint';
import type { RequestType, RequestTypeName, getJSONFn } from '../types/GetJSON';
import type { Translations } from '../types/Localiser';

import defaultSite from './sites/data/defaultSite';
import type { Site } from './sites/types/Site';

export default class Localiser {
  private territory = '';

  public articleLanguage = '';

  public language = '';

  private site: Site;

  public widgets: Record<string, string> = {};

  public data: Record<string, string> = {};

  constructor(language: string, territory: string, site: Site = defaultSite) {
    this.territory = territory || 'US';
    this.articleLanguage = language;
    this.language = this.getLanguage(language);
    this.site = site;
  }

  async load(getJSON: getJSONFn<RequestTypeName>): Promise<void> {
    const { widgets, data } = await this.getLocalisation(getJSON);
    this.widgets = widgets;
    this.data = data;
  }

  async getLocalisation(
    getJSON: getJSONFn<RequestType.TRANSLATIONS_REQUEST>,
  ): Promise<Translations> {
    const { language, site } = this;
    const { body } = await getJSON(`${getAPIEndpoint(site)}/translations.php`, {
      language,
    });
    if (body && body.translations) {
      return body.translations;
    }
    return {
      widgets: {},
      data: {},
    };
  }

  getLanguage(language = ''): string {
    const { territory } = this;

    // Language should match the correct format. E.g. "en" or "en-GB" but not "EN_GB"
    if (language && language.search(/(^\w{2}$)|(\w{2}-\w{2})/g) >= 0) {
      return language && language.indexOf('-') === -1 && territory
        ? `${language}-${territory}`
        : language;
    }
    return 'en-US';
  }

  translate(key: string, replacements: string[] = []): string {
    const { widgets, data } = this;

    /* Use the translation for the language or return the text unchanged. The key will
    be the real text for API data translations (e.g. editorial promo flags) */
    let translatedText = ((): string => {
      if (widgets && widgets[key]) {
        return widgets[key];
      }
      if (data && data[key]) {
        return data[key];
      }
      return key;
    })();

    /* Add the real values we don't want to translate back again & remove
    the '{{' '}}' around the value */
    replacements.forEach((value) => {
      translatedText = translatedText.replace('*', value);
    });

    return translatedText;
  }

  /**
   * Sset the translations for unit tests
   */
  setTranslations(translations: Translations): void {
    this.data = translations.data;
    this.widgets = translations.widgets;
  }
}
