export interface UserState {
  user: null | { is_authenticated: boolean; sub: string; email: string };
  sessionId: string;
  isSessionLoaded: boolean;
  signupForm: {
    error: string;
    isLoading: boolean;
    email: string;
    terms: boolean;
  };
}

export interface Actions {
  getSession: () => void;
  inputUpdate: (update: { email: string } | { terms: boolean }) => void;
  signupUser: (siteName: string, terms: boolean, email?: string) => Promise<void>;
}

export enum ActionTypes {
  GET_SESSION = 'GET_SESSION',
  SIGNUP_INIT = 'SIGNUP_INIT',
  FORM_ERROR = 'FORM_ERROR',
  FORM_INPUT_CHANGE = 'FORM_INPUT_CHANGE',
}

export type UserStateAction =
  | { type: ActionTypes.GET_SESSION; payload: string }
  | { type: ActionTypes.SIGNUP_INIT }
  | { type: ActionTypes.FORM_ERROR; payload: { error: string } }
  | { type: ActionTypes.FORM_INPUT_CHANGE; payload: { email: string } | { terms: boolean } };
