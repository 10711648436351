import React from 'react';

import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';
import ModelCodeReview from '../ModelCodeReview/ModelCodeReview';

import styles from './styles/model-code-reviews.css';

export interface ModelCodeReviewsProps {
  className?: string;
  asinLabelSuffix?: string;
}

const ModelCodeReviews = ({
  className,
  asinLabelSuffix,
}: ModelCodeReviewsProps): JSX.Element | null => (
  <Consumer>
    {(value): JSX.Element | null => {
      const { reviews } = value;
      const containerStyle = className && styles[className] ? className : 'container';

      if (Array.isArray(reviews) && reviews.length > 0) {
        return (
          <div className={styles[containerStyle]}>
            {reviews.map((review) => (
              <ModelCodeReview
                {...{
                  review,
                  key: review.code_name,
                  className,
                  asinLabelSuffix,
                }}
              />
            ))}
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default ModelCodeReviews;
