import React from 'react';

import classNames from 'classnames';

import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import type { GridItemBlockProps } from './model';
import styles from './styles/grid-item-block.css';

const GridItemBlock: React.FC<GridItemBlockProps> = ({
  deal,
  className,
  children,
  stacked,
  dataname,
}) => {
  const { buttonType } = useHawkWidgetContext();

  return (
    <div
      className={classNames(styles['container'], className.gridItemBlock)}
      data-name={dataname}
      data-product-type={deal.product_type}
      data-button-type={buttonType}
      data-stacked={stacked ? 1 : 0}
    >
      {children}
    </div>
  );
};

export default GridItemBlock;
