import type { Site } from './types/Site';

export default (site: Site, keywords: string[] | null = []): Site => {
  if (site.id === 'trd' && keywords && keywords.includes('TRBC')) {
    return {
      ...site,
      analyticsName: `${site.analyticsName}pro`,
    };
  }
  return site;
};
