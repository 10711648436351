import { getCookie } from '../../core/modules/cookie';
import getUrlParameters from '../../utils/getUrlParameters';
import type { Payload } from '../../wss/types/Payload';

export const getVanillaTerritory = (): string => {
  /*
     Determine the territory in following order
     1. Use area parameter in url
     2. use FTR_Country_Code cookie
     3. Use html tag on page
     */

  /* NOTE: the cookie won't be returned on local because the dmain
  doesn't match - so it will need to be manually altered */
  const { area } = getUrlParameters(['area']);
  const cookie = getCookie('FTR_Country_Code');
  const html = document.querySelector('html');

  if (area) {
    return area;
  }
  if (cookie) {
    return cookie;
  }
  return html?.getAttribute('data-locale') ?? '';
};

export const getPurchTerritory = (): string => {
  /*
     Determine the territory in following order
     1. Use area parameter in url
     2. use FTR_Country_Code cookie
     3. Use html tag on page
     */

  /* NOTE: the cookie won't be returned on local because the dmain
  doesn't match - so it will need to be manually altered */
  const { area } = getUrlParameters(['area']);

  if (area) {
    return area;
  }
  if (window.tmnramp && window.tmnramp.geo && window.tmnramp.geo.ctr) {
    return window.tmnramp.geo.ctr;
  }

  return '';
};

export const getServerSideTerritory = (payload: Payload): string => {
  if (String(payload?.territory)) {
    // e.g. 'GB'
    if (payload?.territory?.length === 2) {
      return payload.territory.toUpperCase();
    }
    // e.g. 'en-GB'
    if (payload?.territory?.search(/(\w{2}(-|_)\w{2})/g) >= 0) {
      const territory = payload.territory.match(/(-|_)(\w{2})/);
      if (territory && territory.length >= 3) {
        return territory[2].toUpperCase();
      }
    }
  }
  return 'GB';
};
