import React, { useEffect } from 'react';

import { httpPostRewardActivityProcessorUserReward } from '../../../../../../sharedModules/http/reward/userReward';
import { ProductTypes } from '../../../../../../types/ProductType';
import { useHawkWidgetContext } from '../../../HawkWidget/HawkWidgetProviderContext';

import { Error } from './Error';
import { Loading } from './Loading';
import { getActions } from './reducer/getActions';
import { reducer, getInitialState } from './reducer/reducer';
import { Signup } from './Signup';
import { VultureReveal } from './VultureReveal';

export const VulturePopup: React.FC = () => {
  const { popupDeal, site, translate } = useHawkWidgetContext();

  const isReward = popupDeal?.product_type === ProductTypes.rewards;
  const isSignupFirst =
    !!((popupDeal && popupDeal['promos']) || []).find((p) => p.type === 'signup_first') || isReward;

  const [state, dispatch] = React.useReducer(reducer, getInitialState());

  const actions = getActions(dispatch, translate);

  useEffect(() => {
    if (isSignupFirst && state.isSessionLoaded === false) {
      actions.getSession();
    }
  }, [isSignupFirst]);

  if (isSignupFirst && !state?.user) {
    if (!state.isSessionLoaded) {
      return <Loading />;
    }

    return (
      <Signup
        actions={actions}
        email={state.signupForm.email}
        areTermsAgreed={state.signupForm.terms}
        isLoading={state.signupForm.isLoading}
        error={state.signupForm.error}
      />
    );
  }

  if (popupDeal) {
    const isMerchantNameAvailable = popupDeal.merchant && popupDeal.merchant.name;

    if (isMerchantNameAvailable) {
      if (isReward) {
        // We should always have the user object by this point,
        // however theres a chance the session id is missing.
        // This check aids in typing and catching this edge case
        if (!state?.sessionId || !state?.user) {
          return <Error errorText={translate('missingKiosqSessionError')} />;
        }

        httpPostRewardActivityProcessorUserReward(
          state.user.sub,
          state.user.email,
          site,
          state.sessionId,
          popupDeal.product_key,
        );
      }

      return <VultureReveal />;
    }
  }
  return null;
};
