import type { RequestType, getJSONFn } from '../../../../types/GetJSON';
import type { Suggestion } from '../../UserInput/types/Suggestion';

interface CurriedParams {
  endpoint: string;
  getJSON: getJSONFn<RequestType.SUGGESTIONS_REQUEST>;
}

export const getModelSuggestions =
  ({ getJSON, endpoint }: CurriedParams) =>
  async (value: string, currentSuggestions: Suggestion[]): Promise<Suggestion[]> => {
    if (getJSON && value) {
      const response = await getJSON(`${endpoint}/suggestions.php`, {
        prefix: value,
        rows: 5,
      });
      const { models } = response.body;
      return (models || []).map((model) => {
        return {
          id: model.id,
          label: model.brand ? `${model.brand} ${model.name}` : model.name,
        };
      });
    }
    return currentSuggestions;
  };

export default getModelSuggestions;
