import { getSelectedFilter } from './getSelectedFilter';
import type { HawkWidgetAdvancedState } from './model';

export const getOffset = (
  selectedFilters: HawkWidgetAdvancedState['selectedFilters'],
  activeTab: HawkWidgetAdvancedState['activeTab'],
  pageSize: number,
): number => {
  return (
    Number(getSelectedFilter<number>(selectedFilters, activeTab, 'offset', 0)) +
    Number(getSelectedFilter<number>(selectedFilters, activeTab, 'rows', pageSize))
  );
};
