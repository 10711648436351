import React from 'react';

import classNames from 'classnames';

import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import GridItemBlock from './GridItemBlock';
import type { GridItemMainProps } from './model';
import styles from './styles/grid-item-main.css';

const GridItemMain: React.FC<GridItemMainProps> = ({
  deal,
  children,
  className,
  stacked,
  expanded,
  type,
}) => {
  const { buttonType } = useHawkWidgetContext();

  return (
    <div
      className={classNames(styles['container'], className.gridItemMain)}
      data-product-type={deal?.product_type}
      data-button-type={buttonType}
      data-stacked={stacked ? 1 : 0}
      data-expanded={expanded ? 1 : 0}
      data-type={type || null}
    >
      {children &&
        React.isValidElement(children) &&
        children.props &&
        children.props.children &&
        React.Children.map(children.props.children, (child, index) => {
          if (!React.isValidElement(child)) {
            return null;
          }
          return (
            <GridItemBlock
              dataname={child.key as string}
              deal={deal}
              stacked={stacked}
              className={className}
              // eslint-disable-next-line react/no-array-index-key
              key={`${deal?.id}-${index}`}
            >
              {child}
            </GridItemBlock>
          );
        })}
    </div>
  );
};

export default GridItemMain;
