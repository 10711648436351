import type { Deal } from '../../../types/Deal';
import type { Translate } from '../../../types/Localiser';
import productCostReact from '../../../utils/productCostReact';

export const getPrice = (deal: Deal, type: string): string | number | null => {
  const monthlyPrice = deal?.contract
    ? parseFloat(String(deal?.contract?.monthly_price_for_plan || 0)) +
      parseFloat(String(deal?.contract?.monthly_price_for_phone || 0))
    : 0;
  const upfrontPrice = deal?.offer?.price ? parseFloat(deal.offer.price) : 0;

  switch (type) {
    case 'retail':
    case 'upfront':
      return upfrontPrice;
    case 'monthly':
      return monthlyPrice;
    case 'yearly':
      return deal?.contract?.yearly_price ?? 0;
    case 'salePrice':
      if (deal?.contract) {
        return deal.contract?.sale_price ?? monthlyPrice;
      }
      return deal?.offer?.sale_price ?? upfrontPrice;
    case 'total':
      return deal.contract ? monthlyPrice * deal.contract?.length + upfrontPrice : upfrontPrice;
    case 'wasPrice':
      return deal?.offer?.was_price ? parseFloat(String(deal.offer.was_price)) : '';
    default:
      return null;
  }
};

export const getFormattedPrice = (
  price: string | number | null,
  deal: Deal,
  showOfferLabel: boolean | undefined,
  type: string,
  language: string,
  translate: Translate,
  showPriceRounded: string,
  useConstFreeText?: boolean,
): string | null => {
  const currencyIso = deal?.offer?.currency_iso ?? '';
  /*
        - show buy similar label for buysimilars if it is enabled
        - show a price as 'Free' if it is an upfront and the price is 0
        - show whatever the price is by default
     */
  if (deal?.offer?.label && showOfferLabel) {
    return deal.offer.label;
  }
  if (price === 0 && ['upfront'].indexOf(type) >= 0) {
    if (useConstFreeText) {
      return translate('broadbandCostFree');
    }
    return translate('productCostReactFree');
  }
  if (!price) {
    return null;
  }

  let newPrice = price;
  if (showPriceRounded === 'yes') {
    newPrice = Math.floor(Number(price));
  }

  return productCostReact({
    price: String(newPrice),
    currencyIso,
    language,
  });
};
