import flowRight from 'lodash.flowright';

import { addSource } from './addSource';
import { createImgElement } from './createImgElement';
import type { ImgParams } from './model';
import { updateImgSrc } from './updateImgSrc';

export const composeImage = (imgParams: ImgParams): JSX.Element | null => {
  // We use functional approach here to create image element, order is important
  const img = flowRight(updateImgSrc, createImgElement, addSource)(imgParams);

  return img.element;
};
