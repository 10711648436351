import type { Site } from '../types/Site';

const sites: Site[] = [
  {
    id: 'cw',
    name: 'cyclingweekly',
    value: 'cyclingweekly',
    logo: 'svg/cyclingweekly.svg',
    label: 'Cycling Weekly',
    vanillaName: 'cyclingweekly',
    analyticsName: 'cyclingweekly',
    url: 'cyclingweekly.com',
    ga: {
      id: 'UA-11432820-1',
      format: null,
    },
  },
  {
    id: 'fitandwell',
    name: 'fitandwell',
    value: 'fitandwell',
    logo: 'svg/fitandwell.svg',
    label: 'Fit and Well',
    vanillaName: 'fitandwell',
    analyticsName: 'fitandwell',
    selligent_code: 'FIT',
    url: 'fitandwell.com',
    ga: {
      id: 'UA-48457802-62',
      format: null,
    },
  },
  {
    id: 'gardeningetc',
    name: 'gardeningetc',
    value: 'gardeningetc',
    logo: 'svg/gardeningetc.svg',
    label: 'Gardeningetc',
    vanillaName: 'gardeningetc',
    analyticsName: 'gardeningetc',
    selligent_code: 'GDN',
    url: 'gardeningetc.com',
    ga: {
      id: 'UA-1555272-39',
      format: null,
    },
  },
  {
    id: 'gardeningknowhow',
    name: 'gardeningknowhow',
    value: 'gardeningknowhow',
    logo: 'gnomesingardens.png',
    label: 'Gardening Know How',
    vanillaName: 'gardeningknowhow',
    analyticsName: 'gardeningknowhow',
    url: 'gardeningknowhow.com',
    ga: null,
  },
  {
    id: 'gnomesingardens',
    name: 'gnomesingardens',
    value: 'gnomesingardens',
    logo: 'gnomesingardens.png',
    label: 'gnomesingardens',
    vanillaName: 'gnomesingardens',
    analyticsName: 'gnomesingardens',
    url: 'gnomesingardens.com',
    ga: {
      id: 'UA-0000000-00',
      format: null,
    },
  },
  {
    id: 'golfm',
    name: 'golfmonthly',
    value: 'golfmonthly',
    logo: 'golfmonthly.png',
    label: 'Golf Monthly',
    vanillaName: 'golfmonthly',
    analyticsName: 'golfmonthly',
    url: 'golfmonthly.com',
    ga: {
      id: 'UA-11430160-1',
      format: null,
    },
  },
  {
    id: 'goodto',
    name: 'goodto',
    value: 'goodtoknow',
    logo: 'goodto.png',
    label: 'Good to Know',
    vanillaName: 'goodto',
    analyticsName: 'goodtoknow',
    url: 'goodto.com',
    ga: {
      id: 'UA-11429543-1',
      format: null,
    },
  },
  {
    id: 'homesandgardens',
    name: 'homesandgardens',
    value: 'homesandgardens',
    logo: 'svg/homesandgardens.svg',
    label: 'Homes & Gardens',
    analyticsName: 'homesandgardens',
    url: 'homesandgardens.com',
    ga: {
      id: 'UA-11429207-10',
      format: null,
    },
  },
  {
    id: 'idealh',
    name: 'idealhome',
    value: 'idealhome',
    logo: 'svg/idealhome.svg',
    label: 'Ideal Home',
    vanillaName: 'idealhome',
    analyticsName: 'idealhome',
    url: 'idealhome.co.uk',
    ga: {
      id: 'UA-11429207-1',
      format: null,
    },
  },
  {
    id: 'kpl',
    name: 'kiplinger',
    value: 'kiplinger',
    logo: 'svg/kiplinger.svg',
    label: 'Kiplinger',
    vanillaName: 'kiplinger',
    analyticsName: 'Kiplinger',
    url: 'kiplinger.com',
    ga: {
      id: 'UA-4337061-4',
      format: null,
    },
  },
  {
    id: 'lvg',
    name: 'livingetc',
    value: 'livingetc',
    logo: 'svg/livingetc.svg',
    label: 'Livingetc',
    vanillaName: 'livingetc',
    analyticsName: 'livingetc',
    url: 'livingetc.com',
    ga: {
      id: 'UA-11429207-9',
      format: null,
    },
  },
  {
    id: 'marieclaireuk',
    name: 'marieclaire.co.uk',
    value: 'marieclaire',
    logo: 'svg/marieclaire.svg',
    label: 'Marie Claire.co.uk',
    vanillaName: 'marieclaireuk',
    analyticsName: 'marieclaireuk',
    url: 'marieclaire.co.uk',
    ga: {
      id: 'UA-11429130-1',
      format: null,
    },
  },
  {
    id: 'marieclaireusshop',
    name: 'marieclaire.com/shop',
    value: 'marieclaireusshop',
    logo: 'svg/marieclaire.svg',
    label: 'Marie Claire.com/Shop',
    vanillaName: 'marieclaireusedit',
    analyticsName: 'marieclaireusshop',
    url: 'marieclaire.com/shop',
    urlStage: 'marieclaire.com.master.van-qa.futureplc.engineering/shop/',
    ga: {
      id: 'UA-198556563-1',
      format: null,
    },
  },
  {
    id: 'marieclaireus',
    name: 'marieclaire.com',
    value: 'marieclaireus',
    logo: 'svg/marieclaire.svg',
    label: 'Marie Claire.com',
    vanillaName: 'marieclaireus',
    analyticsName: 'marieclaireus',
    url: 'marieclaire.com',
    ga: {
      id: 'UA-198556563-1',
      format: null,
    },
  },
  {
    id: 'myimperfectlife',
    name: 'myimperfectlife',
    value: 'myimperfectlife',
    logo: 'svg/myimperfectlife.svg',
    label: 'My Imperfect Life',
    vanillaName: 'myimperfectlife',
    analyticsName: 'myimperfectlife',
    url: 'myimperfectlife.com',
    ga: {
      id: 'UA-48457802-68',
      format: null,
    },
  },
  {
    id: 'myvouchercodes',
    name: 'myvouchercodes',
    value: 'myvouchercodes',
    logo: 'myvouchercodes.png',
    label: 'Myvouchercodes',
    vanillaName: 'myvouchercodes',
    analyticsName: 'myvouchercodes',
    url: 'myvouchercodes.co.uk',
    urlStage: 'mvc-staging.tgvg.io',
    ga: {
      id: 'UA-1954547-1',
      format: null,
    },
  },
  {
    id: 'moneyweek',
    name: 'moneyweek',
    value: 'moneyweek',
    logo: 'moneyweek.png',
    label: 'Moneyweek',
    vanillaName: 'moneyweek',
    analyticsName: 'moneyweek',
    url: 'moneyweek.com',
    ga: {
      id: 'UA-1043354-17',
      format: null,
    },
  },
  {
    id: 'realhomes',
    name: 'realhomes',
    value: 'realhomes',
    logo: 'svg/realhomes.svg',
    label: 'Real Homes',
    vanillaName: 'realhomes',
    analyticsName: 'realhomes',
    selligent_code: 'REH',
    url: 'realhomes.com',
    endpoints: {
      stage: 'https://stage.hawk.realhomes.com',
      live: 'https://hawk.realhomes.com',
    },
    ga: {
      id: 'UA-1555272-34',
      format: null,
    },
  },
  {
    id: 'themoneyedit',
    name: 'themoneyedit',
    value: 'themoneyedit',
    logo: 'themoneyedit.jpeg',
    label: 'The Money Edit',
    vanillaName: 'themoneyedit',
    analyticsName: 'themoneyedit',
    url: 'themoneyedit.com',
    ga: {
      id: 'UA-195435841-1',
      format: null,
    },
  },
  {
    id: 'theweek',
    name: 'theweek',
    value: 'theweekus',
    logo: 'svg/theweek.svg',
    label: 'The Week.com',
    vanillaName: 'theweek',
    analyticsName: 'theweek',
    url: 'theweek.com',
    ga: {
      id: 'UA-54145327-3',
      format: null,
    },
  },
  {
    id: 't3',
    name: 't3',
    value: 't3',
    logo: 'svg/t3.svg',
    label: 'T3',
    vanillaName: 't3',
    analyticsName: 't3',
    selligent_code: 'TTT',
    url: 't3.com',
    endpoints: {
      stage: 'https://stage.hawk.t3.com',
      live: 'https://hawk.t3.com',
    },
    ga: {
      id: 'UA-48902248-1',
      format: null,
    },
  },
  {
    id: 'wh',
    name: 'womanandhome',
    value: 'womanandhome',
    logo: 'womanandhome.png',
    label: 'Woman and Home',
    vanillaName: 'womanandhome',
    analyticsName: 'womanandhome',
    url: 'womanandhome.com',
    ga: {
      id: 'UA-11429242-1',
      format: null,
    },
  },
  {
    id: 'wallpaper',
    name: 'wallpaper',
    value: 'wallpaper',
    logo: 'svg/wallpaper.svg',
    label: 'Wallpaper',
    vanillaName: 'wallpaper',
    analyticsName: 'wallpaper',
    url: 'wallpaper.com',
    ga: {
      id: 'UA-67691-2',
      format: null,
    },
  },
  {
    id: 'whowhatwear',
    name: 'whowhatwear',
    value: 'whowhatwear',
    logo: 'whowhatwear.png',
    label: 'Whowhatwear',
    vanillaName: 'whowhatwear',
    analyticsName: 'whowhatwear',
    alias: 'whowhatwearpromo',
    url: 'whowhatwear.com',
    urlStage: 'whowhatwear-promo.com',
    ga: {
      id: 'UA-1024221-1',
      format: null,
    },
  },
];
export default sites;
