import getUrlParameters from '../../utils/getUrlParameters';

/**
 * returns Value of the cookie, or null if it's not been defined yet.
 */
export const getCookie = (name: string): string | null => {
  if (!name || typeof document === 'undefined') {
    return null;
  }
  const { disableCookies } = getUrlParameters(['disableCookies']);
  if (disableCookies === '1') {
    return null;
  }
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          `(?:(?:^|.*;)\\s*${encodeURIComponent(name).replace(
            /[-.+*]/g,
            '\\$&',
          )}\\s*\\=\\s*([^;]*).*$)|^.*$`,
        ),
        '$1',
      ),
    ) || null
  );
};

export const setCookie = (name: string, value: string | number | boolean): boolean => {
  if (
    !name ||
    /^(?:expires|max-age|path|domain|secure)$/i.test(name) ||
    typeof document === 'undefined'
  ) {
    return false;
  }

  const threeDays = new Date(new Date().getTime() + 72 * 60 * 60 * 1000);
  const expires = `; expires=${threeDays.toUTCString()}`;
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${expires}; path=/`;
  return true;
};
