import { setDefaultLink } from './setDefaultLink';

export const setDefaultData = (placeholderData: Record<string, string>): Record<string, string> => {
  return {
    // Set a default widget type to ensure the widget can be processed by client side
    ...(!placeholderData['widget-type'] ? { 'widget-type': 'link' } : {}),
    /** Set a default for link text & show fallback
     *  if they don't have a value and data-link is provided
     **/
    ...setDefaultLink(placeholderData),
  };
};
