import { ActionTypes } from './model';
import type { Action, ScrollerState } from './model';

export const initialState = {
  currentPage: 0,
  currentSwipeX: 0,
  currentStop: false,
} as ScrollerState;

export const reducer = (state: ScrollerState, action: Action): ScrollerState => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case ActionTypes.SET_CURRENT_SWIPE_X: {
      return {
        ...state,
        currentSwipeX: action.payload,
      };
    }
    case ActionTypes.SET_CURRENT_STOP: {
      return {
        ...state,
        currentStop: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
