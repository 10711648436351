import React from 'react';

import classNames from 'classnames';

import type { Tab } from '../../../types/Tab';
import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import { getTabLabel } from './getTabLabel';
import styles from './styles/hawk-tab.css';
import getSrcSet from './utils/getSrcSet';
import { getTabContainerStyle } from './utils/getTabContainerStyle';

interface Props {
  tab: Tab;
  isSpecificNamedTab: boolean;
  tabColor: string;
  isActive: boolean;
  className?: string;
}

const HawkTab = ({
  tab = {} as Tab,
  isActive,
  className,
  tabColor,
  isSpecificNamedTab,
}: Props): JSX.Element => (
  <Consumer>
    {(value): JSX.Element => {
      const {
        changeTab,
        tabs,
        genericSharedComponents: { LazyImage },
      } = value;

      const containerStyle = getTabContainerStyle(
        styles,
        tab,
        isActive,
        isSpecificNamedTab,
        className,
      );
      const containerStyles = classNames({
        [styles[containerStyle]]: true,
        [styles['only-tab']]: tabs.length === 1,
      });

      return (
        <div
          className={classNames(containerStyles)}
          onClick={changeTab}
          data-value={tab.value}
          role="button"
          tabIndex={0}
          onKeyDown={changeTab}
        >
          <div
            className={styles.main}
            data-value={tab.value}
            style={isSpecificNamedTab ? { backgroundColor: tabColor } : {}}
          >
            {tab.image || tab.mobileImage ? (
              <LazyImage
                {...{
                  src: String(tab.mobileImage),
                  srcSet: getSrcSet([
                    {
                      src: tab.mobileImage ? String(tab.mobileImage) : undefined,
                      size: 700,
                    },
                    {
                      src: tab.image ? String(tab.image) : undefined,
                      size: 1200,
                    },
                  ]),
                  alt: tab.label,
                  width: 100,
                  height: 20,
                  className: 'hawk-tab',
                }}
              />
            ) : (
              <span className={styles.label}>{getTabLabel(tab.label)}</span>
            )}
          </div>
          {tab.type === 'label_text_game_platform' && (
            <div
              className={styles['active-bar']}
              style={tabColor ? { backgroundColor: isActive ? tabColor : 'transparent' } : {}}
            />
          )}
        </div>
      );
    }}
  </Consumer>
);

export default HawkTab;
