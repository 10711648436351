export const getTabLabel = (label: string): string => {
  const isMobile =
    typeof window !== 'undefined' &&
    window.matchMedia('only screen and (max-width: 800px)').matches;

  if (label?.length > 38 && !isMobile) {
    return `${label.substring(0, 38)}...`;
  }
  if (label?.length > 22 && isMobile) {
    return `${label.substring(0, 22)}...`;
  }
  return label || '';
};
