import type { WidgetData } from '../../model';

import { getStrongText } from './getStrongText';

export const processDealWidgetModel = (
  model: WidgetData['data']['model'],
  text?: WidgetData['data']['text'],
): Record<string, string> => {
  if (model && model.name) {
    return {
      'model-name': !model.brand ? model.name : `${model.brand} ${model.name}`,
    };
  }

  if (text) {
    /**
     * The default for these two is the link text,
     * hopefully they will get overwritten with something better later.
     * */
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/xml');

    // We determine the label text from the first anchor tag if there is one.
    const bodyNode = doc.getElementsByTagName('body')[0];

    const anchorNode = bodyNode.getElementsByTagName('a')[0];

    const node = anchorNode ? anchorNode.firstChild : bodyNode.firstChild;

    return { 'model-name': `${getStrongText(anchorNode)}${node?.textContent}` };
  }

  return { 'model-name': 'Everything' };
};
