import type { Site } from '../core/modules/sites/types/Site';

import getSiteEndpoint from './getSiteEndpoint';

export default (site: Site): string | undefined => {
  // Support a custom endpoint - necessary if FTE loads hawk from a different domain to the default
  if (typeof window !== 'undefined' && window.hawk_api_endpoint) {
    return window.hawk_api_endpoint;
  }

  const siteEndpoint = getSiteEndpoint(site);
  if (siteEndpoint) {
    return siteEndpoint;
  }

  return process.env.SEARCH_API_ENDPOINT;
};
