export const setDefaultLink = (placeholderData: Record<string, string>): Record<string, string> => {
  if (placeholderData['link']) {
    return {
      ...(!placeholderData['link-text'] ? { 'link-text': 'Visit Site' } : {}),
      ...(!placeholderData['show-fallback'] ? { 'show-fallback': 'button' } : {}),
    };
  }

  return {};
};
