import { WidgetId } from '../../../core/types/Widget';

import { finalizeData } from './finalizeData';
import { WidgetData } from './model';
import { processDealWidgetData } from './processDealWidgetData';
import { readHawkData } from './readHawkData';

export const processNovaWidgetData = (widgetData: WidgetData): Record<string, string> => {
  const processedData = {
    ...(widgetData.type === WidgetId.DEAL ? processDealWidgetData(widgetData) : {}),
    ...(widgetData.data && widgetData.data.attributes ? widgetData.data.attributes : {}),
  };

  // Use hawk property if we do not have any data so far
  if (Object.keys(processedData).length === 0) {
    const hawkData = readHawkData(widgetData);

    return finalizeData(hawkData, widgetData);
  }

  return finalizeData(processedData, widgetData);
};
