import getDate from './getDate';

export default (endTime: number, date: Date = new Date()): number => {
  // Get remaining time
  const endDate = getDate(endTime);

  // Number of seconds between
  // 1000 is the number of milliseconds in a second
  return Math.round((endDate.getTime() - date.getTime()) / 1000);
};
