import { getHawk } from '../../../utils/global';

export const getCachedValue = (key: string): string | number | null => {
  /* Ensure we don't recalculate a value repeatedly (especially if using an event that
    forces style re-calculations) - e.g. the background colour
  This could happen 10s of times on load (e.g. buying guides with 10+ price widgets) */

  const { gaCache } = getHawk();
  if (gaCache && gaCache[key]) {
    return gaCache[key];
  }

  return null;
};
