import getUrlParameters from '../../../../utils/getUrlParameters';

export const getLanguage = (): string => {
  const { forcedLanguage } = getUrlParameters();
  if (typeof forcedLanguage !== 'undefined') {
    return forcedLanguage;
  }
  // Set the language globally so we don't need to pass a static value through every component
  // Get the language from the html tag, falling back to English if it's not set
  const html = document.querySelector('html');
  if (html && html.getAttribute('lang')) {
    return String(html.getAttribute('lang'));
  }

  return 'en';
};
