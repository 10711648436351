import type { Site } from '../core/modules/sites/types/Site';

export default (site: Site): string | null => {
  /* Use the version to determine if it is a branch and that we should
  therefore use the stage endpoint
  Use the specified endpoint for the site (if defined)
  This is mostly for when we build the AMP iframe serverside (HAWK-555), but will
  also be used if the hawk_css_endpoint isn't set by the hawk client */
  const isBranch = (process.env.VERSION || '').search(/\d+\.\d+\.\d+/g) === -1;
  if (process.env.VERSION === 'local') {
    /* Always use what is defined in docker for local, to guarantee we
    can use the local API if necessary */
    return null;
  }
  if (isBranch && site.endpoints && site.endpoints.stage) {
    return site.endpoints.stage;
  }
  if (site.endpoints && site.endpoints.live) {
    return site.endpoints.live;
  }
  return null;
};
