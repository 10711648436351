import type { Tab } from '../../../../types/Tab';

import type { HawkWidgetAdvancedState, SelectedFiltersTab } from './model';

export const getDefaultSelectedFilters = (
  tabConfigs: HawkWidgetAdvancedState['tabConfigs'],
  params: HawkWidgetAdvancedState['params'],
): HawkWidgetAdvancedState['selectedFilters'] =>
  tabConfigs.reduce((acc, tab: Tab) => {
    return {
      ...acc,
      [tab.value]: Object.keys(params[tab.value]).reduce<SelectedFiltersTab>(
        (paramsResult, key) => {
          const getValue = (key): string => {
            if (key === 'model_name') {
              return '';
            }
            if (key === 'sort' && params[tab.value].desc === '1') {
              return `${params[tab.value][key]}_desc`;
            }
            return params[tab.value][key];
          };

          return {
            ...paramsResult,
            params: {
              ...paramsResult.params,
              [key]: params[tab.value][key],
            },
            selected: {
              ...paramsResult.selected,
              // Ensure the model name has no selected value to start with
              // If battle has value e.g 'sort:price' and "desc:'1'" transform into
              // sort:price_desc to make it readable for <Filter component
              [key]: getValue(key),
            },
          };
        },
        // Setup a default monthly & upfront cost as we show these range filters in reverse
        {
          params: {
            filter_monthly_cost: '',
            filter_upfront: '',
          },
          selected: {
            filter_monthly_cost: '',
            filter_upfront: '',
          },
        },
      ),
    };
  }, {});
