import { getSelectedFilter } from './getSelectedFilter';
import type { HawkWidgetAdvancedState } from './model';

export const getModelName = (
  selectedFilters: HawkWidgetAdvancedState['selectedFilters'],
  activeTab: HawkWidgetAdvancedState['activeTab'],
  multiselectModels: HawkWidgetAdvancedState['multiselectModels'],
  modelName = '',
): string => {
  if (multiselectModels) {
    const existingValue = getSelectedFilter<string>(selectedFilters, activeTab, 'model_name', '');

    if (existingValue.toLowerCase().includes(modelName.toLowerCase())) {
      return existingValue;
    }
    return existingValue === '' ? modelName : existingValue.concat(`,${modelName}`);
  }
  return modelName;
};
