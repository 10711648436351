import { WidgetData } from '../model';

export const processDealWidgetLayout = (
  layout: WidgetData['data']['layout'],
): Record<string, string> => {
  if (layout) {
    // Handle the layout being either a string array or an object array
    const layoutValues: string[] = layout.map((layoutEntry: string | { value: string }) => {
      if (typeof layoutEntry !== 'string') {
        return layoutEntry.value;
      }
      return layoutEntry;
    });

    return { 'show-fallback': layoutValues.join('') };
  }

  return {};
};
