import type { WidgetData } from '../model';

import { setDefaultData } from './setDefaultData/setDefaultData';
import { setPriorityData } from './setPriorityData';

export const finalizeData = (
  placeholderData: Record<string, string>,
  widgetData: WidgetData,
): Record<string, string> => {
  return {
    ...placeholderData,
    ...setPriorityData(widgetData),
    ...setDefaultData(placeholderData),
  };
};
