import { loadableReady } from '@loadable/component';

import type { addDynamicWidgets } from '../../../core/types/AddDynamicWidgets';
import type { getPlatformData } from '../../../core/types/GetPlatformData';
import { triggerApstag } from '../../../sharedModules/apstag/integration';

import { processWidgets } from './processWidgets';

export const hawk = async (
  site: string | null,
  getPlatformData: getPlatformData,
  addDynamicWidgets: addDynamicWidgets | null,
): Promise<void> => {
  loadableReady(
    async () => {
      await processWidgets(site, getPlatformData, addDynamicWidgets);
      triggerApstag();
    },
    { namespace: 'hawk' },
  );
};
