import getCurrencySymbol from './getCurrencySymbol';

export interface FormatPriceOptions {
  thousandsSeparator: string;
  decimalSeparator: string;
  priceSymbolSeparator: string;
  price: string;
  symbol: string;
  symbolPosition: string;
}

const formatPrice = (options: FormatPriceOptions): string => {
  const {
    thousandsSeparator,
    decimalSeparator = '.',
    priceSymbolSeparator = '',
    symbol,
    symbolPosition,
  } = options;
  let { price } = options;

  // Set an _ as the decimal separator so we can handle a thousands separator of '.' (DKK)
  const placeholderDecimalSeparator = '_';
  price = price.replace('.', placeholderDecimalSeparator);

  if (thousandsSeparator) {
    const chars = price.substring(0, price.indexOf('_')).split('');
    const charsWithSeparator: string[] = [];
    let separatorCount = 0;
    // Add the thousands separator after every third number
    chars.reverse().forEach((char) => {
      if (separatorCount === 3) {
        charsWithSeparator.unshift(thousandsSeparator);
        separatorCount = 0;
      }
      charsWithSeparator.unshift(char);
      separatorCount++;
    });

    price = `${charsWithSeparator.join('')}${price.substring(
      price.indexOf(placeholderDecimalSeparator),
    )}`;
  }

  // Apply this logic after so we can reference the decimal point to add thousands separators
  // Replace the placeholder separator with the real separator
  // Hide the zeroes if the price ends in '.00'
  price = price
    .replace(placeholderDecimalSeparator, decimalSeparator)
    .replace(`${decimalSeparator}00`, '');

  switch (symbolPosition) {
    case 'after':
      return `${price}${priceSymbolSeparator}${symbol}`;
    default:
      return `${symbol}${priceSymbolSeparator}${price}`;
  }
};

export interface ProductCostReactOptions {
  price?: string | number;
  currencyIso: string;
  language: string;
}

export default (options: ProductCostReactOptions): string => {
  const { price, currencyIso, language } = options;
  const formattedPrice = parseFloat(String(price ?? '')).toFixed(2);
  const symbol = getCurrencySymbol(currencyIso);

  if (!currencyIso) {
    return '';
  }

  switch (currencyIso) {
    case 'NOK':
      // Custom logic for Norway as browsers do not implement this as specified
      // by Norwegian licensees
      // E.g. NOK 1,234.56 is output instead of 1234,56 kr
      // 9,99 kr
      // 999 kr
      // 6999 kr
      return formatPrice({
        decimalSeparator: ',',
        thousandsSeparator: ' ',
        priceSymbolSeparator: ' ',
        price: formattedPrice,
        symbol,
        symbolPosition: 'after',
      });
    case 'AUD':
    case 'SGD':
    case 'CAD':
    case 'NZD':
      return formatPrice({
        decimalSeparator: '.',
        thousandsSeparator: ',',
        priceSymbolSeparator: '',
        price: formattedPrice,
        symbol,
        symbolPosition: 'before',
      });
    default:
      // Minimum fraction digits will cause '.00' to be hidden
      // Only set this to 0 for prices with '.00' to prevent as only showing
      // 1 decimal place (e.g. 118.6)
      return new Intl.NumberFormat(language, {
        style: 'currency',
        currency: currencyIso,
        minimumFractionDigits: formattedPrice.indexOf('.00') >= 0 ? 0 : 2,
      }).format(parseFloat(parseFloat(String(price ?? '')).toFixed(2)));
  }
};
