export default (currencyIso: string): string | null => {
  switch (currencyIso) {
    case 'AUD':
      return 'AU';
    case 'SGD':
      return 'SG';
    case 'CAD':
      return 'CA';
    case 'NZD':
      return 'NZ';
    default:
      return null;
  }
};
