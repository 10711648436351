import React from 'react';

import styles from './styles/scroll-arrow.css';

interface ScrollerArrowProps {
  direction?: string;
  disabled?: boolean;
  onClick?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  className?: string;
  externalClassName?: string;
}

const ScrollerArrow: React.FC<ScrollerArrowProps> = ({
  direction,
  disabled,
  onClick,
  className,
  externalClassName,
}) => (
  <div
    className={`${
      className && styles[className] ? styles[className] : styles['container']
    } ${externalClassName}`}
    data-direction={direction}
    data-disabled={disabled ? 1 : 0}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyDown={onClick}
  >
    <svg
      {...{
        version: '1.1',
        id: 'Layer_1',
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
        viewBox: '0 0 14 25',
        enableBackground: 'new 0 0 14 25',
        xmlSpace: 'preserve',
        className: styles.svg,
      }}
    >
      <path
        className={styles.path}
        d="M12.5,25c-0.3847656,0-0.7685547-0.1464844-1.0625-0.4404297L0.4379883,13.5361328
	c-0.5839844-0.5849609-0.5839844-1.5332031,0.0004883-2.1191406L11.3935547,0.440918
	c0.5849609-0.5869141,1.5341797-0.5878906,2.1210938-0.0019531c0.5869141,0.5849609,0.5869141,1.534668,0.0019531,2.1210938
	l-9.8974609,9.9165039L13.5625,22.4404297c0.5839844,0.5869141,0.5839844,1.5361328-0.0029297,2.1220703
	C13.2666016,24.8535156,12.8828125,25,12.5,25z"
      />
    </svg>
  </div>
);

export default ScrollerArrow;
