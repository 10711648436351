const hasValue =
  (keywords: string[] = []) =>
  (value): string | undefined => {
    return keywords.find((keyword) => keyword.search(new RegExp(value, 'gi')) >= 0);
  };

export default (keywords: string[]): string => {
  keywords = keywords || [];
  const checkValue = hasValue(keywords);
  // Check the boundary to ensure we don't consider 'headphones' as 'phones'
  if (checkValue('(\\s|_)phone')) {
    return 'phones';
  }
  if (checkValue('broadband')) {
    return 'broadband';
  }
  if (checkValue('hosting') || checkValue('antivirus') || checkValue('vpn')) {
    return 'subscriptions';
  }
  return 'retail';
};
