import React from 'react';

import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/title.css';

interface TitleProps {
  title: string;
  className?: string | null;
}

const Title: React.FC<TitleProps> = React.memo(({ title, className }: TitleProps) => (
  <Consumer>
    {(value): JSX.Element | null => {
      const { showWidgetTitle } = value;

      if (showWidgetTitle && title) {
        const containerClassName = className && styles[className] ? className : 'container';
        const Tag = className === 'vulture' ? 'h2' : 'div';

        return <Tag className={styles[containerClassName]}>{title}</Tag>;
      }
      return null;
    }}
  </Consumer>
));

export default Title;
