import React from 'react';

import getItemsPerRow from '../../../modules/getItemsPerRow';
import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';
import type { HawkWidgetProviderContext } from '../HawkWidget/model';
import { Scroller } from '../Scroller/Scroller';

import HawkTab from './HawkTab';
import styles from './styles/hawk-tabs.css';
import { getDealsPerPage } from './utils/getDealsPerPage';
import { getTabsContainerStyle } from './utils/getTabsContainerStyle';

export interface HawkTabsProps {
  className?: string;
  tabsScrollable?: boolean;
}

const HawkTabs: React.FC<HawkTabsProps> = ({ className, tabsScrollable }) => (
  <Consumer>
    {(value): JSX.Element | null => {
      const {
        tabs = [] as HawkWidgetProviderContext['tabs'],
        activeTab = {} as HawkWidgetProviderContext['activeTab'],
      } = value;

      if (tabs.length > 1 || activeTab?.type === 'label_text_game_platform') {
        // TODO: remove once we have support from api for colors for specific named tabs
        const specificTabsColors = {
          ps4: 'var(--playstation-white)',
          ps5: 'var(--playstation-blue)',
          'xbox-one': 'var(--xbox-green)',
          'xbox-series-x': 'var(--black)',
          pc: 'var(--pc-orange)',
          switch: 'var(--nintendo-red)',
          other: 'var(--grey)',
        };

        const isSpecificNamedTab =
          activeTab?.value === 'other'
            ? activeTab?.type === 'label_text_game_platform'
            : !!specificTabsColors[activeTab?.value];

        const containerStyle = getTabsContainerStyle(isSpecificNamedTab, styles, className);

        const pages = getItemsPerRow(tabs, getDealsPerPage());
        const tabColor = specificTabsColors[activeTab?.value];

        return (
          <div className={styles[containerStyle]}>
            <div className={styles.main}>
              {tabsScrollable && (
                <Scroller
                  items={tabs}
                  totalItems={tabs.length}
                  pagesLength={pages.length}
                  className="tabs-widget"
                >
                  {pages.map((pageTabs) => (
                    <div className={styles['wrapper']}>
                      {pageTabs.map((tab) => (
                        <HawkTab
                          tab={tab}
                          isSpecificNamedTab={isSpecificNamedTab}
                          tabColor={specificTabsColors[tab?.value]}
                          isActive={tab.value === activeTab?.value}
                          className={className}
                          key={tab.value}
                        />
                      ))}
                    </div>
                  ))}
                </Scroller>
              )}
              {!tabsScrollable &&
                tabs.map((tab) => (
                  <HawkTab
                    tab={tab}
                    isSpecificNamedTab={isSpecificNamedTab}
                    tabColor={specificTabsColors[tab?.value]}
                    isActive={tab.value === activeTab?.value}
                    className={className}
                    key={tab.value}
                  />
                ))}
            </div>
            {activeTab?.type === 'label_text_game_platform' && (
              <div
                className={styles['active-bar']}
                style={tabColor ? { backgroundColor: tabColor } : {}}
              />
            )}
          </div>
        );
      }

      return null;
    }}
  </Consumer>
);

export default HawkTabs;
