import React from 'react';

import SiteLogo from '../SiteLogo/SiteLogo';

import styles from './styles/spinner.css';

interface SpinnerProps {
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  const containerStyle = className || 'container';

  return (
    <div className={styles[containerStyle]}>
      <div className={styles.overlay} />
      <div className={styles.main}>
        <SiteLogo
          {...{
            className: 'spinner',
          }}
        />
      </div>
    </div>
  );
};

export default Spinner;
