import React from 'react';

import type { GenericSharedComponents } from '../../../types/GenericSharedComponents';
import type { WidgetProps } from '../../../types/WidgetProps';

import HawkWidgetProvider from './HawkWidgetProvider';

interface HawkWidgetStandardProps {
  dealData: WidgetProps['dealData'];
  data: WidgetProps['data'];
  tabConfigs: WidgetProps['tabConfigs'];
  defaultTab: WidgetProps['defaultTab'];
  children?: React.ReactNode;
  genericSharedComponents: GenericSharedComponents;
}

const HawkWidgetStandard: React.FC<HawkWidgetStandardProps> = (props) => {
  const { dealData, data, tabConfigs, defaultTab } = props;

  return (
    <HawkWidgetProvider
      {...{
        ...props,
        dealData,
        tabs: tabConfigs,
        data: data && defaultTab ? data[defaultTab.value] : {},
        activeTab: defaultTab,
      }}
    />
  );
};

export default HawkWidgetStandard;
