import React from 'react';

import { DialogOverlay } from '../../../DialogOverlay';
import { useHawkWidgetContext } from '../../../HawkWidget/HawkWidgetProviderContext';
import Spinner from '../../../Spinner/Spinner';

import styles from './styles/common.css';

export const Loading: React.FC = () => {
  const { setPopupDeal } = useHawkWidgetContext();

  const handleCloseDialog = (): void => {
    setPopupDeal(null);
  };

  return (
    <DialogOverlay closeDialog={handleCloseDialog}>
      <div className={styles.container}>
        <div className={styles.main}>
          <Spinner />
        </div>
      </div>
    </DialogOverlay>
  );
};
