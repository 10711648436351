import getSiteEndpoint from './getSiteEndpoint';

// TODO: requires minor refactor to remove any
/**
 * Get the widgets endpoint (e.g. CDN url or local URL)
 */
export default (site?: any): string | undefined => {
  site = site || {};

  // Support a custom endpoint - necessary if FTE loads hawk from a different domain to the default
  if (typeof window !== 'undefined' && window.hawk_widgets_endpoint) {
    return window.hawk_widgets_endpoint;
  }

  const siteEndpoint = getSiteEndpoint(site);
  if (siteEndpoint) {
    return siteEndpoint;
  }

  return process.env.ASSETS_ENDPOINT;
};
