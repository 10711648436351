import { postJSON } from '../../../assets/modules/ajax';
import { RequestType, SignupAdapterResponse } from '../../../core/types/PostJSON';

export const httpPostKiosqSignup = async (
  email: string,
  site: string,
): Promise<SignupAdapterResponse> => {
  const signupEndpoint = process.env.KIOSQ_SIGN_UP_API ?? '';
  const dataset = {
    site,
    email,
  };
  const response = await postJSON<RequestType.SIGNUP_REQUEST>(signupEndpoint, dataset);

  if (!response.body.success) {
    return { success: false, status: response.status, cookie: '' };
  }

  return {
    success: true,
    status: response.status,
    cookie: response.body.cookie,
  };
};
