import type { Deal, Detail } from '../../../types/Deal';
import { Tab } from '../../../types/Tab';

export interface ScrollerProps {
  autoShuffle?: boolean;
  items: Deal[] | Detail[] | Tab[];
  pagesLength: number;
  loadMoreItems?: (sendGa: boolean) => void;
  totalItems?: number;
  pages?: Deal[][] | Tab[][];
  deal?: Deal;
  className?: string;
  children?: React.ReactNode;
}

export interface ScrollerState extends ScrollerProps {
  currentPage: number;
  currentSwipeX: number;
  currentStop: boolean;
}

export interface Actions {
  setCurrentPage: (currentPage: number) => void;
  setCurrentSwipeX: (currentSwipeX: number) => void;
  setCurrentStop: (currentStop: boolean) => void;
}

export enum ActionTypes {
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_CURRENT_SWIPE_X = 'SET_CURRENT_SWIPE_X',
  SET_CURRENT_STOP = 'SET_CURRENT_STOP',
}

export type Action =
  | {
      type: ActionTypes.SET_CURRENT_PAGE;
      payload: number;
    }
  | {
      type: ActionTypes.SET_CURRENT_SWIPE_X;
      payload: number;
    }
  | {
      type: ActionTypes.SET_CURRENT_STOP;
      payload: boolean;
    };
