import React from 'react';

import styles from './styles/scroller-page.css';

interface ScrollerPageProps {
  active: boolean;
  currentPage: number;
  className?: string;
  children?: React.ReactNode;
}

const ScrollerPage: React.FC<ScrollerPageProps> = ({
  active,
  currentPage,
  children,
  className,
}) => (
  <div
    {...{
      className: className && styles[className] ? styles[className] : styles.container,
      'data-active': active ? 1 : 0,
      style: {
        transform: `translateX(-${`${100 * currentPage}%`})`,
      },
    }}
  >
    {children}
  </div>
);

export default ScrollerPage;
