import React from 'react';

import { Provider } from './HawkWidgetProviderContext';
import type { HawkWidgetProviderContext, HawkWidgetProviderProps } from './model';

const HawkWidgetProvider: React.FC<HawkWidgetProviderProps> = (props) => {
  const {
    children,
    dealData,
    genericSharedComponents: { TrackingPixel },
  } = props;

  return (
    <Provider value={props as HawkWidgetProviderContext}>
      {children}
      <TrackingPixel dealData={dealData} />
    </Provider>
  );
};

export default HawkWidgetProvider;
