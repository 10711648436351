export enum ClickType {
  CHECK_RETAILER = 'Check Retailer',
  INTERNAL = 'internal',
  TOGGLE_FILTERS = 'Toggle Filters',
  READ_MORE = 'Read More',
  READ_LESS = 'Read Less',
  RETAILER = 'retailer',
  CC = 'CC',
  ROW_TOGGLE = 'Row Toggle',
  TAB_CHANGED = 'Tab Changed',
  AMAZON_STAR_RATING = 'Amazon Star Rating',
  WALMART_STAR_RATING = 'Walmart Star Rating',
  PHONE_NUMBER = 'Phone Number',
  REVIEW = 'Review',
  SCROLL_TO_PREVIOUS_PAGE = 'Scroll to previous page',
  SCROLL_TO_NEXT_PAGE = 'Scroll to next page',
  SEASONAL_EVENT_RETAILER = 'Seasonal Event Retailer',
  AMAZON_PRIME = 'Amazon Prime',
  SWITCHER = 'Switcher',
  AVAILABLE_FROM = 'Available From',
  DEALS_PAGE = 'Deals Page',
  FILTER = 'Filter',
  INPUT_FIELD = 'input field',
  COPY_CODE = 'copy code',
  GO_TO_RETAILER = 'go to retailer',
}

export interface GetClickTypeParams {
  link: string;
  articleUrl: string;
  clickType?: ClickType;
  productType?: number;
}

export type GetClickType = ({
  link,
  articleUrl,
  clickType,
  productType,
}: GetClickTypeParams) => ClickType;
