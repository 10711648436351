import { ClickType, GetClickType } from './model';

export const getClickType: GetClickType = ({ link, productType, articleUrl, clickType }) => {
  // Check if link is internal
  try {
    const domain = new URL(link);

    if (articleUrl.includes(domain?.hostname)) {
      return ClickType.INTERNAL;
    }
  } catch (_) {
    if (link.startsWith('/')) {
      return ClickType.INTERNAL;
    }
  }

  if (productType === 200) {
    return ClickType.CHECK_RETAILER;
  }

  if (clickType) {
    return clickType;
  }

  return ClickType.RETAILER;
};
