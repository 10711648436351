import type Localiser from '../../../../core/modules/Localiser';
import type { Site } from '../../../../core/modules/sites/types/Site';
import { renderWidget } from '../../renderWidget';
import type { IProcessedWidget } from '../../renderWidget/updateProcessedWidgets';

interface IProcessWidgetConfig {
  articleCategory: string | null;
  articleId: string | null;
  articleName: string;
  articleType: string | null;
  articleUrl: string;
  editorialOnPage: boolean;
  element: HTMLElement;
  keywords: string[] | null;
  localiser: Localiser;
  platform: string;
  siteConfig: Site;
  territory: string;
  index: number;
}

export const processWidget = async (
  config: IProcessWidgetConfig,
): Promise<IProcessedWidget | null> => {
  const {
    articleCategory,
    articleId,
    articleName,
    articleType,
    articleUrl,
    editorialOnPage,
    element,
    keywords,
    localiser,
    platform,
    siteConfig,
    territory,
    index,
  } = config;
  /* Ignore widgets without a widget type & setup a new index for each rendered widget
  This ensures we generate a different id if there are duplicate widgets on the page
  (e.g. 2 editorial widgets for same model & widget type) */
  if (element.getAttribute('data-widget-type')) {
    const newIndex: number = (index || 0) + 1;

    const renderWidgetReturn = await renderWidget({
      articleCategory,
      articleId,
      articleName,
      articleType,
      articleUrl,
      cacheBust: false,
      editorialOnPage,
      el: element,
      index: newIndex,
      keywords,
      localiser,
      now: null,
      platform,
      site: siteConfig,
      territory,
    });

    return renderWidgetReturn;
  }

  return null;
};
