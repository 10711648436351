import Feature from '../../types/Feature';

import getAttributeValue from './getAttributeValue';
import getDefaultValue from './getDefaultValue';
import getValue from './getValue';
import hasValue from './hasValue';

/**
 * Get the value by using the attributes or the getDefaultValue function
 * Exception for widgetType to ensure it always matches widget.id (this prevents
 * the widget type component from not being respected)
 * and so we can translate the widgetTitle (for serverside)
 */

const getFeatureValue = <T, S>(
  feature: Feature,
  attributes: Record<string, S>,
  defaultReturnValue: T,
): T | S | null => {
  const attributeValue = getAttributeValue(feature, attributes);
  const defaultValue = <T>getDefaultValue(feature);
  const value = getValue(defaultValue, attributeValue);
  return hasValue(value) ? value : defaultReturnValue;
};

export default getFeatureValue;
