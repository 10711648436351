import type { Deal } from '../types/Deal';

export default (deal: Deal): string => {
  if (deal?.contract?.yearly_price && deal.contract.yearly_price !== 0.0) {
    return 'yearly';
  }
  if (deal?.contract?.monthly_price && deal.contract.monthly_price !== '0.00') {
    return 'monthly';
  }
  return 'retail';
};
