const getIndex = <T>(items: T[], selectedItem: T): number => {
  let itemIndex = 0;
  items.every((item, index) => {
    if (item === selectedItem) {
      itemIndex = index;
      return false;
    }
    return true;
  });

  return itemIndex;
};

export default <T>(direction: string, items: T[] = [], selectedItem: T): T => {
  const index = getIndex(items, selectedItem);
  switch (direction) {
    case 'left':
      // This isn't the first item
      if (items[index - 1]) {
        return items[index - 1];
      }
      return items[items.length - 1];
    default:
      // This isn't the last item
      if (items[index + 1]) {
        return items[index + 1];
      }
      return items[0];
  }
};
