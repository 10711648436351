import type { Site } from '../types/Site';

const site: Site = {
  id: '',
  name: '',
  value: '',
  logo: '',
  label: '',
  analyticsName: '',
  url: '',
  ga: {
    id: '',
    format: null,
  },
};
export default site;
