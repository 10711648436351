import React from 'react';

import styles from './styles/display-price-code.css';
import getPriceCode from './utils/getPriceCode';

interface DisplayPriceCodeProps {
  className?: string;
  currencyIso: string;
}

const DisplayPriceCode: React.FC<DisplayPriceCodeProps> = ({ className, currencyIso }) => {
  // If currency iso is in codes, show the currency iso
  const code = getPriceCode(currencyIso);

  if (code) {
    const codeStyle = className && styles[className] ? className : 'container';

    return <span className={styles[codeStyle]}>{code}</span>;
  }
  return null;
};

export default DisplayPriceCode;
