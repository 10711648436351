import { getLabelAPIParams, getAllFeatures } from './features';
import { Site } from './sites/types/Site';

export const getBaseCCUrl = (
  site: Site,
  platform: string,
  language: string,
  url = '',
): string | null => {
  if (['responsive', 'progressive'].includes(platform)) {
    if (process.env.VERSION === 'local') {
      return `/playground/${site.id}/comparison.html`;
    }

    // Prefix the /deals/compare url with the locale or language if one is present in the url
    // This ensures the correct /deals/compare url is used on internationalised sites
    // We need to have an exception for GB as the language is en-GB but the platform urls are /uk/
    let locale = language.replace(/.*-/, '').toLowerCase();
    if (locale === 'gb') {
      locale = 'uk';
    }

    const prefix = ((): string | null => {
      if (url.indexOf(`/${locale}/`) >= 0) {
        return locale;
      }
      if (url.indexOf(`/${language.toLowerCase()}/`) >= 0) {
        return language.toLowerCase();
      }
      return null;
    })();
    if (prefix) {
      return `/${prefix}/deals/compare`;
    }
    return '/deals/compare';
  }
  return null;
};

// TODO: remove any after adding types for params in whole app
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCCParams = (params: any, articleId = ''): any => {
  let result = getAllFeatures().reduce((result, feature) => {
    if (params[feature.id]) {
      return {
        ...result,
        [feature.value]: params[feature.id],
      };
    }

    return result;
  }, {});

  if (articleId) {
    result = {
      ...result,
      article_id: articleId,
    };
  }

  // Add the label params separately as these aren't in widgetFeatures (dynamic features)
  return {
    ...result,
    ...getLabelAPIParams(params),
  };
};
