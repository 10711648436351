import defaultSite from './data/defaultSite';
import postProcessSite from './postProcessSite';
import type { Site } from './types/Site';

/**
 * Mapping for full site name to the site name (script name & api value)
 * This is to avoid FTE needing to maintain a mapping on their side, causing a
 * deploy on both sides when a new site is added
 */
export default (
  href: string,
  sites: Site[],
  site: string | null = null,
  keywords: string[],
): Site => {
  if (site) {
    site = site.toLowerCase();
  }
  /* Get the site config for the specified site, but return the vanilla config if
  there is another site with the same value
  We want to use the vanilla site serverside if it is setup (FTE will send us the real site name) */
  const siteConfig =
    sites.find((config) => {
      const previewRegex = new RegExp(`(preview.vanilla.tools/[^/]+/${config.vanillaName})`);

      return (
        site === config.id ||
        site === config.name ||
        site === config.vanillaName ||
        href.includes(`/${config.id}/`) || // playground, cypress - check with "/" to avoid false positives. E.g. "th" matching "whathifi"
        href.includes(`/${config.vanillaName}`) || // vanilla sites
        previewRegex.test(href) ||
        href.includes(`${config.alias}.`) || // stage/live site for an additional domain - e.g. goodto.com
        href.includes(`/${config.name}`) || // CMS preview
        href.includes(`${config.url}`) || // stage/live site - for sites with similar domain marieclaire
        href.includes(`${config.urlStage}`) // stage - for sites where a different config needs to be detected based on path marieclaireus/marieclaireusshop
      );
    }) || defaultSite;

  return postProcessSite(siteConfig, keywords);
};
