import React from 'react';

import type { Deal } from '../../../types/Deal';
import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/display-price-contract-type.css';

interface DisplayPriceContractTypeProps {
  deal: Deal;
  className?: string;
  type?: string;
}

const DisplayPriceContractType: React.FC<DisplayPriceContractTypeProps> = (props) => (
  <Consumer>
    {(value): JSX.Element | null => {
      const { translate } = value;
      const { className, type } = props;
      let { deal } = props;
      deal = deal || {};

      if (deal?.contract) {
        const containerStyle = className && styles[className] ? className : 'container';

        return (
          <div className={styles[containerStyle]}>
            {((): string | null => {
              switch (type) {
                case 'monthly':
                  return translate('displayPriceContractTypeMonthly');
                case 'yearly':
                  return translate('displayPriceContractTypeYearly');
                default:
                  return null;
              }
            })()}
          </div>
        );
      }
      return null;
    }}
  </Consumer>
);

export default DisplayPriceContractType;
