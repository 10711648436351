import React from 'react';

import getDefaultTitle from '../../../modules/getDefaultTitle';
import { Consumer } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/main-toggle.css';

interface MainToggleProps {
  toggleDisplay: () => void;
  toggleOpen: boolean;
}

const MainToggle: React.FC<MainToggleProps> = ({ toggleDisplay, toggleOpen }) => (
  <Consumer>
    {(value): JSX.Element => {
      const { data, models, translate } = value;

      return (
        <div
          className={styles.container}
          onClick={toggleDisplay}
          role="button"
          tabIndex={0}
          onKeyDown={toggleDisplay}
        >
          <span className={styles.title}>
            {translate('reviewToggleTitle', [
              getDefaultTitle(data, models as Record<string, number>),
            ])}
          </span>
          {toggleOpen ? (
            <span className={styles.icon}>&#9650;</span>
          ) : (
            <span className={styles.icon}>&#9660;</span>
          )}
        </div>
      );
    }}
  </Consumer>
);

export default MainToggle;
