import type {
  ParametersType as getParametersType,
  RequestTypeName as getRequestTypeName,
  ResponseType as getResponseType,
} from '../../core/types/GetJSON';
import {
  ParametersType as postParametersType,
  RequestTypeName as postRequestTypeName,
  ResponseType as postResponseType,
} from '../../core/types/PostJSON';
import buildUrl from '../../utils/buildUrl';
import type { Response, PostResponse } from '../types/Response';

import { getNodes } from './getNodes';

/**
 * CORS AJAX get JSON, no jQuery
 */
export const getJSON = async <T extends getRequestTypeName>(
  url: string,
  params: getParametersType<T> | Record<string, never>,
): Promise<Response<getResponseType<T>>> => {
  if (typeof fetch !== 'undefined') {
    // Send the request
    const response = await fetch(buildUrl<T>(url, params));
    if (response && response.json) {
      return {
        body: await response.json(),
        responseTime: 0,
      };
    }
    return {
      body: {},
      responseTime: 0,
    };
  }
  return {
    body: {},
    responseTime: 0,
  };
};

/**
 *
 * @param {string} filename
 */
export const getStylesheet = (filename: string): void => {
  const stylesheetExists =
    document.querySelectorAll(`link[rel="stylesheet"][href="${filename}"]`).length > 0;

  if (stylesheetExists) {
    return;
  }
  let rafExt;
  if (typeof requestAnimationFrame !== 'undefined') {
    rafExt = requestAnimationFrame;
  } else if (typeof mozRequestAnimationFrame !== 'undefined') {
    rafExt = mozRequestAnimationFrame;
  } else if (typeof webkitRequestAnimationFrame !== 'undefined') {
    rafExt = webkitRequestAnimationFrame;
  } else if (typeof msRequestAnimationFrame !== 'undefined') {
    rafExt = msRequestAnimationFrame;
  }

  const loadAssets = async (): Promise<void> => {
    const head = await getNodes({
      selector: 'head',
    });
    if (head.length > 0) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = filename;
      head[0].appendChild(link);
    }
  };

  if (rafExt) {
    rafExt(loadAssets);
  } else {
    window.addEventListener('load', loadAssets);
  }
};

/**
 *
 * @param {string} url
 * @param {object} data
 * @returns {Promise.<object>}
 */
export const postJSON = async <T extends postRequestTypeName>(
  url: string,
  data: postParametersType<T>,
): Promise<PostResponse<postResponseType<T>>> => {
  // Send the request
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    if (response.ok) {
      return {
        status: response.status,
        body: await response.json(),
        responseTime: 0,
      };
    }

    return {
      status: response.status,
      body: {},
      responseTime: 0,
    };
  } catch (e) {
    return {
      status: 500,
      body: {},
      responseTime: 0,
    };
  }
};
