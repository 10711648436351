import { getSelectedFilter } from './getSelectedFilter';
import type { HawkWidgetAdvancedState } from './model';

interface GetCheckedValueParams {
  selectedFilters: HawkWidgetAdvancedState['selectedFilters'];
  activeTab: HawkWidgetAdvancedState['activeTab'];
  filterKey?: string;
  value?: string;
  checked?: boolean | string;
}

export const getCheckedValue = ({
  selectedFilters,
  activeTab,
  filterKey,
  value,
  checked,
}: GetCheckedValueParams): string => {
  // Multi select checkbox group - add the value, or remove it if it already selected
  let newValues = getSelectedFilter<string>(selectedFilters, activeTab, filterKey, '').split(',');
  newValues = newValues.filter((value) => value !== '');
  // Already checked so remove from newValues
  if (checked) {
    newValues = newValues.filter((newValue) => newValue !== value);
  } else if (value) {
    // Otherwise add the value
    newValues.push(value);
  }

  return newValues.join(',');
};
