export default function getLinkElement(
  element: HTMLElement | null,
  count = 0,
): HTMLAnchorElement | null {
  if (element) {
    // Get the element that is a link
    // keep checking the parent until we find a matching element, with a maximum of 10 elements
    // to avoid setting an infinite loop
    if (element.nodeName === 'A' && element instanceof HTMLAnchorElement) {
      return element;
    }
    if (!(element.parentNode instanceof HTMLElement) || count >= 10) {
      return null;
    }

    return getLinkElement(element.parentNode, count + 1);
  }
  return null;
}
