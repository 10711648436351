import { WidgetId } from '../types/Widget';
import type { Widget } from '../types/Widget';

const widgetConfigs: Widget[] = [
  {
    id: WidgetId.BESTOF,
    dir: 'BestOf',
    label: 'Best of',
    gaLabel: 'Best of widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.BUNDLE,
    dir: 'BundleWidget',
    label: 'Bundle',
    gaLabel: 'Bundle widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.BUTTON,
    dir: 'ButtonWidget',
    label: 'Button',
    gaLabel: 'Button widget',
    endpoint: 'widget.php',
    sites: [],
    group: 'default',
  },
  {
    id: WidgetId.ANF_MULTIMODEL,
    dir: 'multimodel',
    label: 'ANF Multimodel',
    gaLabel: 'ANF Multimodel widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.ANF_COLLECTION_TEMPLATE,
    dir: 'anfcollectiontemplate',
    label: 'ANF Collection Template',
    gaLabel: 'ANF Collection Template widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.ANF_BUTTON,
    dir: 'button',
    label: 'ANF Button',
    gaLabel: 'ANF Button widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.COMPARISON,
    dir: 'Comparison',
    label: 'Comparison Chart',
    gaLabel: 'CC',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.DEAL,
    dir: 'DealWidget',
    label: 'Deal',
    gaLabel: 'Deal widget',
    endpoint: 'widget.php',
    sites: [],
    group: 'default',
  },
  {
    id: WidgetId.DOTW,
    dir: 'DealOfTheWeek',
    label: 'Deal of the Week',
    gaLabel: 'Deal of the Week widget',
    endpoint: 'widget.php',
    sites: [],
    group: 'default',
  },
  {
    id: WidgetId.EDITORS_PICK,
    dir: 'EditorsPick',
    label: "Editor's Pick",
    gaLabel: "Editor's Pick widget",
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.TABS,
    dir: 'Tabs',
    label: 'Tabs',
    gaLabel: 'Tabs widget',
    endpoint: 'widget.php',
    sites: ['all'],
    aliases: [WidgetId.GAME_PLATFORM],
    group: 'default',
  },
  {
    id: WidgetId.INBODY,
    dir: 'InBody',
    label: 'In Body',
    gaLabel: 'In Body widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'popular',
  },
  {
    id: WidgetId.LABELS,
    dir: 'LabelsWidget',
    label: 'Labels',
    gaLabel: 'Labels widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.LINK,
    dir: 'LinkWidget',
    label: 'Link',
    gaLabel: 'Link widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.MAGAZINE_SUBSCRIPTIONS,
    dir: 'MagazineSubscriptions',
    label: 'Magazine Subscriptions',
    gaLabel: 'Magazine Subscriptions Widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.MERCHANT_LINK,
    dir: 'MerchantLinkWidget',
    label: 'Merchant Link',
    gaLabel: 'Merchant Link widget',
    endpoint: 'widget.php',
    aliases: [WidgetId.ALIAS_TABLEPRICE],
    sites: ['all'],
    group: 'popular',
  },
  {
    id: WidgetId.MERCHANT_OVERVIEW,
    dir: 'MerchantOverview',
    label: 'MerchantOverview',
    gaLabel: 'MerchantOverview widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.MONA_WIDGET,
    dir: 'MonaWidget',
    label: 'Mona Widget',
    gaLabel: 'Mona widget',
    endpoint: 'widget.php',
    sites: [],
    group: 'default',
  },
  {
    id: WidgetId.MOST_POPULAR_OFFERS,
    dir: 'mostpopularoffers',
    label: 'MostPopularOffers Widget',
    gaLabel: 'MostPopularOffers Widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.MULTI_MODEL_REVIEW,
    dir: 'MultiModelReview',
    label: 'Multi-Model Review',
    gaLabel: 'Multi-Model Review widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'popular',
  },
  {
    id: WidgetId.NIGHTHAWK,
    dir: 'Nighthawk',
    label: 'Nighthawk',
    gaLabel: 'Nighthawk widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.OVERLAY,
    dir: 'Overlay',
    label: 'Overlay',
    gaLabel: 'Overlay widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.PARTNER,
    dir: 'Partner',
    label: 'Partner',
    gaLabel: 'Partner widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.PEACOCK,
    dir: 'Peacock',
    label: 'Peacock',
    gaLabel: 'Peacock widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.POCKET,
    dir: 'Pocket',
    label: 'Pocket',
    gaLabel: 'Pocket widget',
    endpoint: 'widget.php',
    sites: [],
    group: 'default',
  },
  {
    id: WidgetId.PRICE,
    dir: 'Price',
    label: 'Price',
    gaLabel: 'Price widget',
    endpoint: 'widget.php',
    aliases: [WidgetId.ALIAS_FULLPRICE],
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.PRODUCT_NAME,
    dir: 'ProductName',
    label: 'Product Name',
    gaLabel: 'Product Name widget',
    endpoint: 'widget.php',
    sites: ['all'],
    aliases: [WidgetId.ALIAS_PRODUCTNAME, WidgetId.ALIAS_MODELNAME],
    group: 'popular',
  },
  {
    id: WidgetId.PRODUCT_BLOCK,
    dir: 'ProductBlock',
    label: 'ProductBlock',
    gaLabel: 'ProductBlock widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.PROMOTION,
    dir: 'Promotion',
    label: 'Promotion',
    gaLabel: 'Promotion widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.REVIEW,
    dir: 'Review',
    label: 'Review',
    gaLabel: 'Review widget',
    endpoint: 'widget.php',
    aliases: [WidgetId.ALIAS_ALPINE],
    sites: ['all'],
    group: 'popular',
  },
  {
    id: WidgetId.SCROLLABLE_TILE,
    dir: 'ScrollableTile',
    label: 'Scrollable Tile',
    gaLabel: 'Scrollable Tile widget',
    endpoint: 'widget.php',
    sites: [],
    group: 'default',
  },
  {
    id: WidgetId.SEASONAL,
    dir: 'Seasonal',
    label: 'Seasonal',
    gaLabel: 'Seasonal widget',
    endpoint: 'seasonal.php',
    sites: [],
    group: 'default',
  },
  {
    id: WidgetId.SHOP,
    dir: 'Shop',
    label: 'Shop',
    gaLabel: 'Affiliate Shop',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'popular',
  },
  {
    id: WidgetId.SIMPLE,
    dir: 'Simple',
    label: 'Simple',
    gaLabel: 'Simple widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.SPECS,
    dir: 'Specs',
    label: 'Specs',
    gaLabel: 'Specs widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.STICKY,
    dir: 'Sticky',
    label: 'Sticky',
    gaLabel: 'Sticky widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.STREAMING,
    dir: 'Streaming',
    label: 'Streaming',
    gaLabel: 'Streaming widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.TILE,
    dir: 'Tile',
    label: 'Tile Widget',
    gaLabel: 'Tile Widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.USER_REVIEWS,
    dir: 'UserReviews',
    label: 'UserReviews',
    gaLabel: 'UserReviews widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.VULTURE,
    dir: 'Vulture',
    label: 'Vulture',
    gaLabel: 'Vulture widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
  {
    id: WidgetId.BROADBAND,
    dir: 'BroadbandWidget',
    label: 'Broadband',
    gaLabel: 'Broadband widget',
    endpoint: 'widget.php',
    sites: ['all'],
    group: 'default',
  },
];
export default widgetConfigs;
