import type { UnbuiltPlaceholders } from './hawk/processWidgets/model';

export const buildPlaceholders = async (
  arrayOfUnbuiltPlaceholders: UnbuiltPlaceholders[],
): Promise<Array<HTMLElement | null>> => {
  const unprocessedWidgets: Array<HTMLElement | null> = [];

  for (const unbuiltPlaceholders of arrayOfUnbuiltPlaceholders) {
    const { elements, callbackToBuildPlaceholder } = unbuiltPlaceholders;

    for (const element of elements) {
      if (element) {
        const unprocessedResult = await callbackToBuildPlaceholder({ element });
        unprocessedWidgets.push(...unprocessedResult);
      }
    }
  }

  return unprocessedWidgets;
};
