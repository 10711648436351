import React from 'react';

import { CookieKeys, getCookie } from '../../../../../../../sharedModules/getCookie';
import { httpPostKiosqSignup } from '../../../../../../../sharedModules/http/kiosq/signup';
import { HawkEvents } from '../../../../../../../types/HawkEvents';
import { Translate } from '../../../../../../types/Localiser';
import { UserStateAction, Actions, ActionTypes } from '../types';

import { isKiosqUserUpdated } from './modules/getKiosqState';

const getSignupErrorFromStatus = (translate: Translate, status: number): string => {
  switch (status) {
    case 409:
      return translate('signupErrorExistingUser');
    case 429:
      return translate('signupErrorTooManyRequests');
    case 500:
    default:
      return 'An unknown error occurred, please try again later.';
  }
};

export const getActions = (
  dispatch: React.Dispatch<UserStateAction>,
  translate: Translate,
): Actions => {
  const getSession: Actions['getSession'] = () => {
    const gaCookie = getCookie(CookieKeys.GA);
    dispatch({ type: ActionTypes.GET_SESSION, payload: gaCookie });
  };

  const inputUpdate: Actions['inputUpdate'] = (update) => {
    dispatch({ type: ActionTypes.FORM_INPUT_CHANGE, payload: update });
  };

  const signupUser = async (siteName: string, terms: boolean, email?: string): Promise<void> => {
    dispatch({ type: ActionTypes.SIGNUP_INIT });
    if (!email || !terms) {
      return dispatch({
        type: ActionTypes.FORM_ERROR,
        payload: { error: translate('signupSubmitErrorMissingDetails') },
      });
    }

    const signupResponse = await httpPostKiosqSignup(email, siteName);
    if (!signupResponse.success) {
      return dispatch({
        type: ActionTypes.FORM_ERROR,
        payload: { error: getSignupErrorFromStatus(translate, signupResponse.status) },
      });
    }

    document.cookie = `${CookieKeys.KIOSQ}=${signupResponse.cookie}`;
    window.dispatchEvent(new Event(HawkEvents.KIOSQ_COOKIE_CHANGE));
    const isUserStateUpdated = await isKiosqUserUpdated();
    if (!isUserStateUpdated) {
      return dispatch({
        type: ActionTypes.FORM_ERROR,
        payload: { error: translate('missingKiosqSessionError') },
      });
    }

    return getSession();
  };
  return React.useMemo<Actions>(
    () => ({
      getSession,
      inputUpdate,
      signupUser,
    }),
    [],
  );
};
