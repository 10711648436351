import { useState, useEffect } from 'react';

import {
  getRemainingDays,
  getRemainingHours,
  getRemainingMinutes,
  getRemainingSeconds,
  getDate,
  formatLeadingZero,
  getDealDateString,
} from '../../../modules/countdownTiming';
import getTotalRemainingSeconds from '../../../modules/countdownTiming/getTotalRemainingSeconds';
import type { Translate } from '../../../types/Localiser';

enum StatusEnum {
  UNKNOWN = 'unknown',
  NOT_STARTED = 'not-started',
  IN_PROGRESS = 'in-progress',
  EXPIRED = 'expired',
}

interface ICountdownStatus {
  timeString: string;
  label: string;
  status: `${StatusEnum}`;
}

export default (
  dealStartTime: number | null,
  dealEndTime: number | null,
  translate: Translate,
  language: string,
  area: string,
): ICountdownStatus => {
  // Get the time for the current date and the deal start and end dates
  const currentDate = new Date();
  const currentTime = currentDate.getTime();
  const startTime = dealStartTime ? getDate(dealStartTime).getTime() : null;
  const endDate = getDate(dealEndTime ?? 0);
  const endTime = endDate.getTime();

  // Get the number of hours, minutes & seconds remaining before the deal expires
  const days = getRemainingDays(dealEndTime ?? 0, currentDate);
  const hours = formatLeadingZero(getRemainingHours(dealEndTime ?? 0, currentDate));
  const minutes = formatLeadingZero(getRemainingMinutes(dealEndTime ?? 0, currentDate));
  const seconds = formatLeadingZero(getRemainingSeconds(dealEndTime ?? 0, currentDate));

  const [secondsLeft, setSecondsLeft] = useState(
    dealEndTime ? getTotalRemainingSeconds(dealEndTime, currentDate) : 0,
  );

  useEffect(() => {
    const timer = setInterval(() => {
      if (days <= 0 && secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000);
    return () => clearInterval(timer);
  });

  if (startTime && dealStartTime && currentTime < startTime) {
    // Deal has not started yet
    return {
      timeString: getDealDateString(dealStartTime, language, area),
      label: translate('dealCountdownStarts'),
      status: StatusEnum.NOT_STARTED,
    };
  }
  if (
    endTime &&
    dealEndTime &&
    (!startTime || (currentTime > startTime && startTime)) &&
    currentTime < endTime
  ) {
    // Deal is in progress if current time is later than the start time
    // and the end time hasn't been reached
    // Or if there is no start time and the end time hasn't been reached

    // Countdown if less than 24 hours before expiry
    if (days <= 0) {
      return {
        timeString: translate('dealCountdownTime', [hours, minutes, seconds]),
        label: `${translate('dealCountdownEndsIn')} `,
        status: StatusEnum.IN_PROGRESS,
      };
    }

    return {
      timeString: getDealDateString(dealEndTime, language, area),
      label: `${translate('dealCountdownEnds')} `,
      status: StatusEnum.IN_PROGRESS,
    };
  }
  if (endTime && currentTime >= endTime) {
    // Deal has expired
    return { timeString: translate('dealCountdownExpired'), label: '', status: StatusEnum.EXPIRED };
  }

  return { timeString: '', label: '', status: StatusEnum.UNKNOWN };
};
