import type { Deal } from '../../../../types/Deal';

export const getUrl = (deal: Deal, label?: string): string | null => {
  // Prime shipping
  if (label === 'Free trial') {
    return deal.shipping ? deal.shipping.url : '';
  }

  return deal.offer && deal.offer.link;
};
