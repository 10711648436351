import { Response, AirdaleResponse } from '../../../assets/types/Response';
import { getJSONFn, RequestType } from '../../../core/types/GetJSON';
import { getAirdaleParams } from '../../../sharedModules/getAirdaleParams';

interface Params {
  site: string;
  length?: number;
  retailerCategory: string;
  territory: string;
  fields?: string;
}

export const fetchHttpAiredaleFeeds = async (
  params: Params,
  getJSON: getJSONFn<RequestType.AIRDALE_REQUEST>,
): Promise<Response<AirdaleResponse>> => {
  const { site, length = 100, retailerCategory, territory, fields } = params;

  const requestParams = getAirdaleParams(site, length, retailerCategory, territory, fields);
  try {
    const response = await getJSON(process.env.AIRDALE_ENDPOINT || '', requestParams);
    return response;
  } catch (error) {
    return {} as Response<AirdaleResponse>;
  }
};
