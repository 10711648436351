import { updateHawk } from '../../../utils/global';
import { getNodes } from '../getNodes';

export const getEditorialWidgetStatus = (): boolean => {
  // Find placeholders with render type "editorial" or no render type (we default to editorial)
  const widgetPlaceholders = getNodes({
    selector:
      '.hawk-nest[data-render-type="editorial"],.hawk-nest:not([data-render-type]),.hawk-placeholder[data-render-type="editorial"],.hawk-placeholder:not([data-render-type])',
  });
  /* Don't consider link & inbody widgets to be editorial - i.e. never skip them even if there
  is an editorial widget on the page */
  const hasEditorialWidgets =
    widgetPlaceholders.filter(
      (el) => ['link', 'inbody', 'deal'].indexOf(el.getAttribute('data-widget-type') || '') === -1,
    ).length > 0;
  updateHawk({
    editorialOnPage: hasEditorialWidgets,
  });

  return hasEditorialWidgets;
};
