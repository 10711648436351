export default (currency: string): string => {
  switch (currency) {
    case 'GBP':
      return '£';
    case 'NGN':
      return '₦';
    case 'JPY':
      return '¥';
    case 'EUR':
      return '€';
    case 'KRW':
      return '₩';
    case 'USD':
    case 'AUD':
    case 'CAD':
    case 'SGD':
    case 'NZD':
      return '$';
    case 'INR':
      return '₹';
    case 'THB':
      return '฿';
    case 'PHP':
      return '₱';
    case 'IDR':
      return 'Rp';
    case 'MYR':
      return 'RM';
    case 'NOK':
    case 'SEK':
    case 'DKK':
      return 'kr';
    default:
      return currency;
  }
};
