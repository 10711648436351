import React from 'react';

import type { ImgParams } from './model';

export const addSource = (imgParams: ImgParams): ImgParams => {
  const { source, element } = imgParams;

  if (source) {
    return {
      ...imgParams,
      element: (
        <picture>
          {source}
          {element}
        </picture>
      ),
    };
  }

  return imgParams;
};
