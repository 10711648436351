import type { Translate } from '../types/Localiser';

export default (amount: number | undefined, translate: Translate): string | number => {
  if (typeof amount === 'undefined' || amount === null) {
    return translate('n/a');
  }
  if (Math.floor(amount) === 99999 || Math.floor(amount) === 999999) {
    return translate('unlimitedValue');
  }

  return amount;
};
