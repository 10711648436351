import { markPerformanceAPI, measurePerformanceAPI } from '../../../../analytics-library/entry';
import Localiser from '../../../../core/modules/Localiser';
import getSite from '../../../../core/modules/sites/getSite';
import { addDynamicWidgets } from '../../../../core/types/AddDynamicWidgets';
import { getPlatformData } from '../../../../core/types/GetPlatformData';
import { HawkEvents } from '../../../../types/HawkEvents';
import getWidgetsEndpoint from '../../../../utils/getWidgetsEndpoint';
import { updateHawk } from '../../../../utils/global';
import { getJSON } from '../../ajax';
import { getNodes } from '../../getNodes';
import preparePlaceholders from '../../preparePlaceholders';
import { processNovaWidgetData, ProcessNovaWidgetEvent } from '../../processNovaWidgetData';
import { getEditorialWidgetStatus } from '../../renderWidget/getEditorialWidgetStatus';

import { getLanguage } from './getLanguage';
import { handleInfiniteScrollArticles } from './handleInfiniteScrollArticles';
import { processWidget } from './processWidget';
import { removeWidgetData } from './removeWidgetData';
import { resolveWidgets } from './resolveWidgets';

export const processWidgets = async (
  site: string | null,
  getPlatformData: getPlatformData,
  addDynamicWidgets: addDynamicWidgets | null,
): Promise<void> => {
  /* Set the translations in window.hawk here instead of in hawk.ts so they
  are available in entry scripts
  E.g. setting widget title override on WHATHIFI */
  const language = getLanguage();
  const platformData = await getPlatformData();
  const siteConfig = getSite(
    document.location.href,
    platformData.sites,
    site,
    platformData.keywords || [],
  );
  const editorialOnPage = getEditorialWidgetStatus();

  const localiser = new Localiser(language, platformData.territory);
  await localiser.load(getJSON);

  // Check we have an endpoint. If we don't, skip the setup as we can't load the chunks
  const endpoint = getWidgetsEndpoint(siteConfig);
  /* Set the webpack public path dynamically - this needs to be different depending on
  where the script is loaded
  E.g. stage, live, local
  The script is loaded via CDN and is not on the same url as the site
  Must add a slash after the endpoint for chunks */
  __webpack_public_path__ = `${endpoint}/`;

  // Remove data from local storage to ensure widgets can render after subsequent page loads
  removeWidgetData();

  updateHawk({
    version: process.env.VERSION,
    processWidget,
    articleType: platformData.articleType,
    articleCategory: platformData.articleCategory,
    articleId: platformData.articleId,
    keywords: platformData.keywords,
    territory: platformData.territory,
    platform: platformData.platform,
    site: siteConfig,
    localiser,
    articleName: document.title,
    articleUrl: document.location.href,
  });

  const unprocessedWidgets: Array<HTMLElement | null> = getNodes({
    selector: '.hawk-nest,.hawk-placeholder',
  });

  const unprocessedDynamicWidgets: Array<HTMLElement | null> = addDynamicWidgets
    ? await addDynamicWidgets({
        articleName: document.title,
        articleUrl: document.location.href,
        editorialOnPage,
        localiser,
        platformData,
        siteConfig,
      })
    : [];

  resolveWidgets({
    unprocessedWidgets: unprocessedWidgets.concat(unprocessedDynamicWidgets),
    platformData,
    siteConfig,
    localiser,
    editorialOnPage,
  });

  markPerformanceAPI('All widgets rendered', { detail: 'HAWK' });
  measurePerformanceAPI('Time until all widgets rendered', { end: 'All widgets rendered' });

  handleInfiniteScrollArticles({
    siteConfig,
    localiser,
    editorialOnPage,
    platformData,
  });

  document.addEventListener(
    HawkEvents.HAWK_PROCESS_NOVA_WIDGET,
    async (event: CustomEventInit<ProcessNovaWidgetEvent>) => {
      if (event?.detail?.el) {
        const element = await preparePlaceholders({
          dataset: processNovaWidgetData(event.detail.widgetData),
          node: event.detail.el,
          position: 'end',
          tagName: 'aside',
        });

        resolveWidgets({
          unprocessedWidgets: [element],
          siteConfig,
          localiser,
          editorialOnPage,
          platformData,
        });
      }
    },
  );
};
