import React, { useContext } from 'react';

import getImageUrl from '../../../../modules/getImageUrl';
import type { ReviewData } from '../../../../types/ModelInfo';
import { Context } from '../../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/streaming-score.css';

interface IStreamingScoreProps {
  review?: ReviewData;
}

export const StreamingScore: React.FC<IStreamingScoreProps> = ({ review }) => {
  const {
    genericSharedComponents: { LazyImage },
  } = useContext(Context);
  if (review) {
    const { review_score, code_name, code_value } = review;

    const getReviewText = (): string => {
      switch (code_name) {
        case 'rt':
          return `${review_score}%`;
        case 'imdb':
          return `${review.review_score}/10`;
        default:
          return '';
      }
    };

    return (
      <span className={styles['container']}>
        <LazyImage
          {...{
            src: getImageUrl(`misc/${code_name}-icon.png`),
            alt: code_value,
            width: 26,
            height: 18,
            className: 'streaming-score',
          }}
        />
        <div className={styles['review-text']}>{getReviewText()}</div>
      </span>
    );
  }
  return null;
};
