import { httpFetchSearchApiRedeemUniqueCodePerDeal } from '../../../../../../../../sharedModules/http/searchApi/redeem';
import { Site } from '../../../../../../../modules/sites/types/Site';
import { Deal } from '../../../../../../../types/Deal';
import { Translate } from '../../../../../../../types/Localiser';

export const loadUniqueCode = async (
  setLoading: (value: boolean) => boolean,
  setApiCall: (status: string) => void,
  setUniqueCodeData: (code: string) => void,
  translate: Translate,
  endpoint: string,
  site: Site,
  area: string,
  popupDeal: Deal,
): Promise<void> => {
  try {
    setLoading(true);
    const uniqueCode = await httpFetchSearchApiRedeemUniqueCodePerDeal(
      endpoint,
      site,
      area,
      popupDeal,
    );

    setLoading(false);
    setApiCall('success');

    return setUniqueCodeData(uniqueCode);
  } catch (e) {
    setLoading(false);
    setApiCall('failure');

    return setUniqueCodeData(translate('ranOutOfCodesMessage'));
  }
};
