import type { Deal } from '../../types/Deal';
import type { DealImage } from '../../types/DealImage';
import getImageUrl from '../getImageUrl';
import getShortName from '../getShortName';

import getImageWithUpdatedWidth from './getImageWithUpdatedWidth';

export const getDealImage = (deal: Deal, defaultPriority?: string[], width?: number): DealImage => {
  let priority = defaultPriority || ['product', 'label', 'model', 'placeholder'];

  // Only show the placeholder image for view similars
  if (deal?.product_type && deal.product_type === 200) {
    priority = priority.filter((priority) => !['product', 'label', 'model'].includes(priority));
  }

  return priority.reduce(
    (value: DealImage, currentPriority: string) => {
      if (!value.url) {
        // Get the product image
        // If not found use the model label image
        // If not found use the default model image
        // Or use the placeholder image
        if (currentPriority === 'product' && deal?.image) {
          return {
            url: getImageWithUpdatedWidth(deal.image, width),
            alt: getShortName(deal?.offer?.name, 30, '...'),
            type: currentPriority,
          };
        }
        if (currentPriority === 'label' && deal.label_image) {
          return {
            url: getImageWithUpdatedWidth(deal.label_image, width),
            alt: getShortName(deal.offer && deal.offer.name, 30, '...'),
            type: currentPriority,
          };
        }
        if (currentPriority === 'model' && deal.model_image) {
          return {
            url: getImageWithUpdatedWidth(deal.model_image, width),
            alt: getShortName(deal.offer && deal.offer.name, 30, '...'),
            type: currentPriority,
          };
        }
        if (currentPriority === 'merchant' && deal.merchant && deal.merchant.logo_url) {
          return {
            url: getImageWithUpdatedWidth(deal.merchant.logo_url, width),
            alt: getShortName(deal.merchant && String(deal.merchant.name), 30, '...'),
            type: currentPriority,
          };
        }
        if (currentPriority === 'network' && deal.contract && deal.contract.network_logo_url) {
          return {
            url: getImageWithUpdatedWidth(deal.contract.network_logo_url, width),
            alt: getShortName(deal.contract && deal.contract.network, 30, '...'),
            type: currentPriority,
          };
        }
        if (currentPriority === 'placeholder') {
          return {
            url: getImageUrl('misc/no-image-available.svg'),
            alt: getShortName(deal.offer && deal.offer.name, 30, '...'),
            type: currentPriority,
          };
        }
      }
      return value;
    },
    {
      url: '',
      alt: '',
      type: '',
    },
  );
};
