import viewports from './viewports.json';

interface Viewport {
  name: string;
  type: string;
  value: number;
}

const getFilteredViewports = (width: number): Viewport[] => {
  const result = viewports.filter(({ name, type, value }) => {
    if (type === 'min' && width >= value) {
      return name;
    }
    if (type === 'max' && width <= value) {
      return name;
    }
    return false;
  });

  if (result.length === 0) {
    return viewports.filter(({ name }) => name === 'desktop');
  }

  return result;
};

/**
 * Return the matching viewport names so we can add this to the widget
 * placeholder for client side element queries
 */
export default (width: number): string => {
  const filteredViewports = getFilteredViewports(width);
  return filteredViewports.map((v) => v.name).join(',');
};
