import React from 'react';

import retailDataResponse from '../../../../../../spec/testData/data/retailDataResponse.json';
import type { WidgetRequestParameters } from '../../../../../assets/types/Request';
import type { SiteWithPlatform } from '../../../../modules/sites/types/Site';
import type { APIData } from '../../../../types/APIData';
import { DealData } from '../../../../types/DealData';
import { Filter } from '../../../../types/Filter';
import type { RequestType, getJSONFn } from '../../../../types/GetJSON';
import type { HawkWidgetProviderContext } from '../model';

export interface HawkWidgetAdvancedProps {
  data?: HawkWidgetProviderContext['data'];
  defaultTab?: HawkWidgetProviderContext['defaultTab'];
  endpoint: HawkWidgetProviderContext['endpoint'];
  getDealData: HawkWidgetProviderContext['getDealData'];
  dealData: HawkWidgetProviderContext['dealData'];
  getJSON: HawkWidgetProviderContext['getJSON'];
  getSortOptions?: HawkWidgetProviderContext['getSortOptions'];
  models?: HawkWidgetProviderContext['models'];
  multiselectModels?: HawkWidgetProviderContext['multiselectModels'];
  params?: HawkWidgetProviderContext['params'];
  selectedSwitcherItem?: HawkWidgetProviderContext['selectedSwitcherItem'];
  sendAnalytics: HawkWidgetProviderContext['sendAnalytics'];
  setDealData: HawkWidgetProviderContext['setDealData'];
  site?: HawkWidgetProviderContext['site'];
  switcherItems?: HawkWidgetProviderContext['switcherItems'];
  tabConfigs?: HawkWidgetProviderContext['tabConfigs'];
  toggleFilters?: HawkWidgetProviderContext['toggleFilters'];
  translate: HawkWidgetProviderContext['translate'];
  widget?: HawkWidgetProviderContext['widget'];
  widgetEndpoint: HawkWidgetProviderContext['widgetEndpoint'];
  genericSharedComponents: HawkWidgetProviderContext['genericSharedComponents'];
  children?: React.ReactNode;
}

export enum TabValues {
  DEALS = 'deals',
  CONTRACTS = 'contracts',
}

export interface Filters {
  filter_upfront: string;
  filter_monthly_cost: string;
  filter_product_types: string;
  filter_postcode: string;
  model_name: string;
  postcode: string;
  language: string;
  site: string;
  distinct_networks: number;
  multi: number;
  keep_duplicities: number;
  rows: number;
  device: string;
  origin: string;
  offset: number | null;
  sort: string;
  desc: string;
}

export interface TabConfig {
  value: TabValues;
  label: string;
  countProperty: string;
  params: Partial<Filters>;
  category: string;
  pageSize: number;
}

export interface SelectedFiltersTab {
  params: Partial<Filters>;
  selected: Partial<Filters>;
}

export type SelectedFilters = {
  [tab in TabValues]?: SelectedFiltersTab;
};

export interface GaData {
  label?: string;
  clickType?: string;
}

export interface SortOptions {
  value: string;
  label?: string;
  filterValue: string;
  formattedValue: string;
  desc: string;
}

export type Data = {
  [tab in TabValues]?: Omit<typeof retailDataResponse, 'filters'> & {
    filters: Filter[];
  };
};

export interface HawkWidgetAdvancedState extends HawkWidgetAdvancedProps {
  paramsChanged: boolean;
  postcodeChanged: boolean;
  activeTab: TabConfig;
  tabConfigs: TabConfig[];
  switcherItems: string[];
  selectedSwitcherItem: string;
  selectedFilters: SelectedFilters;
  params: Filters;
  currentPage: number;
  gaData: GaData;
  currentParams: Partial<Filters>;
  currentSelectedFilters: Partial<Filters>;
  defaultSelectedFilters: Partial<Filters>;
  sortOptions: SortOptions[];
  getSortOptions: (tab: HawkWidgetAdvancedState['activeTab']) => SortOptions[];
  dataLoading: boolean;
  sendPostRequest: boolean;
  // TODO: we should check this, this seems to be Data for HawkWidgetAdvanced
  // but APIData for widgets
  data: APIData;
  objectOfModels: Record<string, number>;
}

export type ActionsPayloads = {
  updateSelectedFilters: {
    tab: HawkWidgetAdvancedState['activeTab']['value'];
    property: string;
    param: number;
    selected: number;
  };
  resetSelectedFilters: {
    tab: HawkWidgetAdvancedState['activeTab']['value'];
    values: HawkWidgetAdvancedState['selectedFilters'];
  };
  changeRangeFilterValue: {
    value?: number | string;
  };
  loadParentModel: {
    name: string;
  };
  updateModelSuggestions: {
    label: string;
  };
};

export interface Actions {
  scrollLeft: () => void;
  scrollRight: () => void;
  changeTab: (
    event: React.SyntheticEvent<HTMLDivElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  setCurrentPage: (currentPage: number) => void;
  loadMoreDeals: (sendGa: boolean) => void;
  showFewerDeals: () => void;
  changeFilter: (
    event: React.SyntheticEvent<HTMLDivElement> | React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  changeRangeFilter: (
    filterKey: string,
    filterValue: ActionsPayloads['changeRangeFilterValue'],
  ) => void;
  changeSort: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  toggleCheckbox: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  changeCheckboxGroup: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  changeRadioButtonGroup: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  updateInputValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  updateInputPostcode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submitInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  submitPostcode: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearFilters: () => void;
  changePage: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  loadParentModel: (parentModel: ActionsPayloads['loadParentModel']) => void;
  updateModelSuggestions: (suggestion: ActionsPayloads['updateModelSuggestions']) => void;
  getData: (
    params: WidgetRequestParameters,
    appendOffers: boolean,
    widgetEndpoint: string,
    getJSON: getJSONFn<RequestType.WIDGET_REQUEST | RequestType.AIRDALE_REQUEST>,
    state: HawkWidgetAdvancedState,
    site?: SiteWithPlatform,
  ) => Promise<APIData>;
  postData: (
    params: WidgetRequestParameters,
    appendOffers: boolean,
    widgetEndpoint: string,
    getJSON: any,
    state: HawkWidgetAdvancedState,
    site?: SiteWithPlatform,
  ) => Promise<APIData>;
  sortSimilarAtLastPosition: (data: any) => void;
}

export enum ActionTypes {
  SCROLL_LEFT = 'SCROLL_LEFT',
  SCROLL_RIGHT = 'SCROLL_RIGHT',
  CHANGE_TAB = 'CHANGE_TAB',
  UPDATE_SELECTED_FILTERS = 'UPDATE_SELECTED_FILTERS',
  RESET_SELECTED_FILTERS = 'RESET_SELECTED_FILTERS',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  LOAD_MORE_DEALS = 'LOAD_MORE_DEALS',
  SHOW_FEWER_DEALS = 'SHOW_FEWER_DEALS',
  CHANGE_FILTER = 'CHANGE_FILTER',
  CHANGE_RANGE_FILTER = 'CHANGE_RANGE_FILTER',
  CHANGE_SORT = 'CHANGE_SORT',
  TOGGLE_CHECKBOX = 'TOGGLE_CHECKBOX',
  CHANGE_CHECKBOX_GROUP = 'CHANGE_CHECKBOX_GROUP',
  CHANGE_RADIO_BUTTON_GROUP = 'CHANGE_RADIO_BUTTON_GROUP',
  UPDATE_INPUT_VALUE = 'UPDATE_INPUT_VALUE',
  UPDATE_INPUT_POSTCODE = 'UPDATE_INPUT_POSTCODE',
  SUBMIT_INPUT = 'SUBMIT_INPUT',
  SUBMIT_POSTCODE = 'SUBMIT_POSTCODE',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  CHANGE_PAGE = 'CHANGE_PAGE',
  LOAD_PARENT_MODEL = 'LOAD_PARENT_MODEL',
  UPDATE_MODEL_SUGGESTIONS = 'UPDATE_MODEL_SUGGESTIONS',
  SET_DATA_LOADING = 'SET_DATA_LOADING',
  UPDATE_DATA = 'UPDATE_DATA',
  SORT_SIMILAR_TO_LAST_POSITION = 'SORT_SIMILAR_TO_LAST_POSITION',
}

export type Action =
  | { type: ActionTypes.SCROLL_LEFT }
  | { type: ActionTypes.SCROLL_RIGHT }
  | {
      type: ActionTypes.CHANGE_TAB;
      payload: React.SyntheticEvent<HTMLDivElement> | React.ChangeEvent<HTMLSelectElement>;
    }
  | {
      type: ActionTypes.UPDATE_SELECTED_FILTERS;
      payload: ActionsPayloads['updateSelectedFilters'];
    }
  | {
      type: ActionTypes.RESET_SELECTED_FILTERS;
      payload: ActionsPayloads['resetSelectedFilters'];
    }
  | {
      type: ActionTypes.SET_CURRENT_PAGE;
      payload: number;
    }
  | {
      type: ActionTypes.LOAD_MORE_DEALS;
      payload: boolean;
    }
  | {
      type: ActionTypes.SHOW_FEWER_DEALS;
    }
  | {
      type: ActionTypes.CHANGE_FILTER;
      payload: React.SyntheticEvent<HTMLDivElement> | React.ChangeEvent<HTMLSelectElement>;
    }
  | {
      type: ActionTypes.CHANGE_RANGE_FILTER;
      payload: {
        filterKey: string;
        filterValue: ActionsPayloads['changeRangeFilterValue'];
      };
    }
  | {
      type: ActionTypes.CHANGE_SORT;
      payload: React.ChangeEvent<HTMLSelectElement>;
    }
  | {
      type: ActionTypes.TOGGLE_CHECKBOX;
      payload: React.SyntheticEvent<HTMLDivElement>;
    }
  | {
      type: ActionTypes.CHANGE_CHECKBOX_GROUP;
      payload: React.SyntheticEvent<HTMLDivElement>;
    }
  | {
      type: ActionTypes.CHANGE_RADIO_BUTTON_GROUP;
      payload: React.ChangeEvent<HTMLSelectElement>;
    }
  | {
      type: ActionTypes.UPDATE_INPUT_VALUE;
      payload: React.ChangeEvent<HTMLInputElement>;
    }
  | {
      type: ActionTypes.UPDATE_INPUT_POSTCODE;
      payload: React.ChangeEvent<HTMLInputElement>;
    }
  | {
      type: ActionTypes.SUBMIT_INPUT;
      payload: React.ChangeEvent<HTMLInputElement>;
    }
  | {
      type: ActionTypes.SUBMIT_POSTCODE;
      payload: React.ChangeEvent<HTMLInputElement>;
    }
  | {
      type: ActionTypes.CLEAR_FILTERS;
    }
  | {
      type: ActionTypes.CHANGE_PAGE;
      payload: React.SyntheticEvent<HTMLDivElement>;
    }
  | {
      type: ActionTypes.LOAD_PARENT_MODEL;
      payload: ActionsPayloads['loadParentModel'];
    }
  | {
      type: ActionTypes.UPDATE_MODEL_SUGGESTIONS;
      payload: ActionsPayloads['updateModelSuggestions'];
    }
  | {
      type: ActionTypes.SET_DATA_LOADING;
      payload: boolean;
    }
  | {
      type: ActionTypes.UPDATE_DATA;
      payload: {
        dealData: DealData;
        data: any;
        models: any;
      };
    }
  | {
      payload: any;
      type: ActionTypes.SORT_SIMILAR_TO_LAST_POSITION;
    };
