import React from 'react';

import { useHawkWidgetContext } from '../HawkWidget/HawkWidgetProviderContext';

import styles from './styles/grid-item-toggle.css';

interface GridItemToggleProps {
  expanded: boolean;
  toggleExpanded: () => void;
}

const GridItemToggle: React.FC<GridItemToggleProps> = ({ expanded, toggleExpanded }) => {
  const { translate } = useHawkWidgetContext();
  const containerStyle = expanded ? 'expanded-container' : 'container';

  return (
    <div
      className={styles[containerStyle]}
      onClick={toggleExpanded}
      role="button"
      tabIndex={0}
      onKeyDown={toggleExpanded}
    >
      <span className={styles.label}>
        {translate(expanded ? 'expandedRowToggleExpanded' : 'expandedRowToggleHidden')}
      </span>
    </div>
  );
};

export default GridItemToggle;
