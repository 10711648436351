import { ClickType } from '../../../../../sharedModules/getClickType';
import { setCookie } from '../../../../modules/cookie';
import getDataset from '../../../../modules/getDataset';
import getSwitcherItem from '../../../../modules/getSwitcherItem';
import type { Tab } from '../../../../types/Tab';

import { getCheckedValue } from './getCheckedValue';
import { getDefaultSelectedFilters } from './getDefaultSelectedFilters';
import { getFilterValueMultiSelect } from './getFilterValueMultiSelect';
import { getModelName } from './getModelName';
import { getNewSelectedFilters } from './getNewSelectedFilters';
import { getOffset } from './getOffset';
import { getSelectedFilter } from './getSelectedFilter';
import { ActionTypes } from './model';
import type { Action, HawkWidgetAdvancedState } from './model';
import { stringCompare } from './stringCompare';

// Remove as
export const getInitialState = ({
  selectedSwitcherItem,
  switcherItems,
  activeTab,
  tabConfigs,
  params,
  getSortOptions,
  multiselectModels,
  defaultData,
  defaultModels,
  dealData,
  getDealData,
}): HawkWidgetAdvancedState => {
  const defaultSelectedFilters = getDefaultSelectedFilters(tabConfigs, params);

  return {
    selectedSwitcherItem,
    switcherItems,
    activeTab,
    tabConfigs,
    currentParams: defaultSelectedFilters[activeTab?.value]?.params,
    params,
    selectedFilters: getDefaultSelectedFilters(tabConfigs, params),
    currentSelectedFilters: defaultSelectedFilters,
    defaultSelectedFilters,
    currentPage: 1,
    sortOptions: getSortOptions ? getSortOptions(activeTab) : [],
    getSortOptions,
    multiselectModels,
    dataLoading: false,
    sendPostRequest: false,
    data: defaultData,
    objectOfModels: defaultModels,
    dealData,
    getDealData,
  } as HawkWidgetAdvancedState;
};

// TODO: refactor some actions to not need retyping possibly undefined strings from DOMStringMap into string

export const reducer = (
  state: HawkWidgetAdvancedState,
  action: Action,
): HawkWidgetAdvancedState => {
  switch (action.type) {
    case ActionTypes.UPDATE_DATA: {
      return {
        ...state,
        paramsChanged: false,
        postcodeChanged: false,
        sendPostRequest: false,
        dataLoading: false,
        objectOfModels: action.payload.models,
        dealData: action.payload.dealData,
        data: action.payload.data,
      };
    }
    case ActionTypes.UPDATE_MODEL_SUGGESTIONS: {
      const modelName = getModelName(
        state.selectedFilters,
        state.activeTab,
        state.multiselectModels,
        action.payload.label,
      );

      if (modelName) {
        const newSelectedFilters = getNewSelectedFilters({
          tabName: state.activeTab?.value,
          tab: state.selectedFilters[state.activeTab?.value],
          filters: {
            params: {
              model_name: modelName,
              offset: 0,
            },
            selected: {
              model_name: '',
              offset: 0,
            },
          },
        });

        const paramsChanged = !stringCompare(
          state.selectedFilters[state.activeTab?.value]?.params,
          newSelectedFilters[state.activeTab?.value]?.params,
        );

        return {
          ...state,
          selectedFilters: newSelectedFilters,
          gaData: {
            label: action.payload.label,
            clickType: 'Model suggestion',
          },
          paramsChanged,
          postcodeChanged: false,
          dataLoading: paramsChanged,
          // TODO: These two properties should be handled in particular components
          currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
          currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
        };
      }

      return state;
    }
    case ActionTypes.LOAD_PARENT_MODEL: {
      const newSelectedFilters = getNewSelectedFilters({
        tabName: state.activeTab?.value,
        tab: state.selectedFilters[state.activeTab?.value],
        filters: {
          params: {
            model_name: action.payload.name,
            offset: 0,
          },
          selected: {
            model_name: '',
            offset: 0,
          },
        },
      });

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        gaData: {
          label: action.payload.name,
          clickType: 'Back to parent model navigation',
        },
        paramsChanged,
        postcodeChanged: false,
        dataLoading: paramsChanged,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.CHANGE_PAGE: {
      // target is always HTMLElement here and never EventTarget
      const { value } = getDataset(action.payload.target as HTMLElement);

      const { rows } = state.params[state.activeTab?.value];
      const offset = value ? Number(value) * rows - rows : null;

      const newSelectedFilters = {
        ...state.defaultSelectedFilters,
        ...getNewSelectedFilters({
          tabName: state.activeTab?.value,
          tab: state.selectedFilters[state.activeTab?.value],
          filters: {
            params: {
              offset,
            },
            selected: {
              offset,
            },
          },
        }),
      };

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        gaData: {
          label: `Page ${value}`,
          clickType: 'Pagination',
        },
        currentPage: Math.floor(Number(value)),
        paramsChanged,
        dataLoading: paramsChanged,
        postcodeChanged: false,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.CLEAR_FILTERS: {
      // Update UI with default filter and sort selections
      const newSelectedFilters = {
        ...state.selectedFilters,
        [state.activeTab?.value]: state.defaultSelectedFilters[state.activeTab?.value],
      };

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        gaData: {
          clickType: 'Clear filters',
        },
        paramsChanged,
        dataLoading: paramsChanged,
        postcodeChanged: false,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.SUBMIT_INPUT: {
      const { value, type } = getDataset(action.payload.target);
      const newValue = value || action.payload.target.value;

      // Skip if there is no value (e.g. suggestion was clicked)
      if (newValue) {
        const modelName = getModelName(
          state.selectedFilters,
          state.activeTab,
          state.multiselectModels,
          newValue,
        );

        if (modelName) {
          const newSelectedFilters = getNewSelectedFilters({
            tabName: state.activeTab?.value,
            tab: state.selectedFilters[state.activeTab?.value],
            filters: {
              params: {
                [type as string]: modelName,
                offset: 0,
              },
              selected: {
                [type as string]: '',
                offset: 0,
              },
            },
          });

          const paramsChanged = !stringCompare(
            state.selectedFilters[state.activeTab?.value]?.params,
            newSelectedFilters[state.activeTab?.value]?.params,
          );

          return {
            ...state,
            selectedFilters: newSelectedFilters,
            gaData: {
              label: modelName,
              clickType: 'User input submitted',
            },
            paramsChanged,
            postcodeChanged: false,
            dataLoading: paramsChanged,
            // TODO: These two properties should be handled in particular components
            currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
            currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
          };
        }

        return state;
      }

      return state;
    }
    case ActionTypes.SUBMIT_POSTCODE: {
      return {
        ...state,
        sendPostRequest: state.postcodeChanged,
        dataLoading: state.postcodeChanged,
        postcodeChanged: false,
      };
    }
    case ActionTypes.UPDATE_INPUT_POSTCODE: {
      const newSelectedFilters = getNewSelectedFilters({
        tabName: state.activeTab?.value,
        tab: state.selectedFilters[state.activeTab?.value],
        filters: {
          params: {
            postcode: action.payload.target.value,
          },
          selected: {
            postcode: action.payload.target.value,
          },
        },
      });

      const postcodeChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params?.postcode,
        newSelectedFilters[state.activeTab?.value]?.params?.postcode,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        paramsChanged: false,
        postcodeChanged,
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.UPDATE_INPUT_VALUE: {
      const { type } = getDataset(action.payload.target);

      // Use a separate value for the param & selected so we handle the
      // currentinput value separately
      // We don't know when (or if) we'll want to use it for an API request
      const newSelectedFilters = getNewSelectedFilters({
        tabName: state.activeTab?.value,
        tab: state.selectedFilters[state.activeTab?.value],
        filters: {
          params: {
            [type as string]: getSelectedFilter<string>(
              state.selectedFilters,
              state.activeTab,
              type,
              '',
            ),
          },
          selected: {
            [type as string]: action.payload.target.value,
          },
        },
      });

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        paramsChanged,
        postcodeChanged: false,
        dataLoading: paramsChanged,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.CHANGE_RADIO_BUTTON_GROUP: {
      const { value, filterKey, checked } = getDataset(action.payload.target, 'value');

      // Toggle the checked status
      const checkedValue = !checked ? value : '';

      const newSelectedFilters = {
        ...state.defaultSelectedFilters,
        ...getNewSelectedFilters({
          tabName: state.activeTab?.value,
          tab: state.selectedFilters[state.activeTab?.value],
          filters: {
            params: {
              [filterKey as string]: checkedValue,
              offset: 0,
            },
            selected: {
              [filterKey as string]: checkedValue,
              offset: 0,
            },
          },
        }),
      };

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        gaData: {
          label: `${filterKey}=${checkedValue}`,
          clickType: 'Radio button group',
        },
        paramsChanged,
        postcodeChanged: false,
        dataLoading: paramsChanged,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.CHANGE_CHECKBOX_GROUP: {
      // target is always HTMLElement here and never EventTarget
      const { value, filterKey, checked } = getDataset(action.payload.target as HTMLElement);

      // Toggle the checked status - checkbox groups are always multiselect
      const checkedValue = getCheckedValue({
        selectedFilters: state.selectedFilters,
        activeTab: state.activeTab,
        filterKey,
        checked: Boolean(checked),
        value,
      });

      const newSelectedFilters = {
        ...state.defaultSelectedFilters,
        ...getNewSelectedFilters({
          tabName: state.activeTab?.value,
          tab: state.selectedFilters[state.activeTab?.value],
          filters: {
            params: {
              [filterKey as string]: checkedValue,
              offset: 0,
            },
            selected: {
              [filterKey as string]: checkedValue,
              offset: 0,
            },
          },
        }),
      };

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        gaData: {
          label: `${filterKey}=${checkedValue}`,
          clickType: 'Checkbox group',
        },
        paramsChanged,
        postcodeChanged: false,
        dataLoading: paramsChanged,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.TOGGLE_CHECKBOX: {
      // target is always HTMLElement here and never EventTarget
      const { checked, filterKey } = getDataset(action.payload.target as HTMLElement);

      // Toggle the checked status
      const checkedValue = !checked ? '1' : '';

      const newSelectedFilters = {
        ...state.defaultSelectedFilters,
        ...getNewSelectedFilters({
          tabName: state.activeTab?.value,
          tab: state.selectedFilters[state.activeTab?.value],
          filters: {
            params: {
              [filterKey as string]: checkedValue,
              offset: 0,
            },
            selected: {
              [filterKey as string]: checkedValue,
              offset: 0,
            },
          },
        }),
      };

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        gaData: { clickType: 'Checkbox' },
        paramsChanged,
        postcodeChanged: false,
        dataLoading: paramsChanged,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.CHANGE_SORT: {
      const { value: dataValue } = getDataset(action.payload.target);
      const sortOption = state.sortOptions.find(
        (sort) => sort.value === action.payload.target.value || sort.value === dataValue,
      );

      if (sortOption) {
        const { value, filterValue, desc } = sortOption;

        const newSelectedFilters = {
          ...state.defaultSelectedFilters,
          ...getNewSelectedFilters({
            tabName: state.activeTab?.value,
            tab: state.defaultSelectedFilters[state.activeTab?.value],
            filters: {
              params: {
                sort: filterValue,
                desc,
                offset: 0,
              },
              selected: {
                sort: value,
                desc,
                offset: 0,
              },
            },
          }),
        };

        const paramsChanged = !stringCompare(
          state.selectedFilters[state.activeTab?.value]?.params,
          newSelectedFilters[state.activeTab?.value]?.params,
        );

        return {
          ...state,
          selectedFilters: newSelectedFilters,
          gaData: { clickType: 'Sort' },
          paramsChanged,
          postcodeChanged: false,
          dataLoading: paramsChanged,
          // TODO: These two properties should be handled in particular components
          currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
          currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
        };
      }

      return state;
    }
    case ActionTypes.CHANGE_RANGE_FILTER: {
      const newSelectedFilters = {
        ...state.defaultSelectedFilters,
        ...getNewSelectedFilters({
          tabName: state.activeTab?.value,
          tab: state.selectedFilters[state.activeTab?.value],
          filters: {
            params: {
              [action.payload.filterKey]: `${action.payload.filterValue.value}`,
              offset: 0,
            },
            selected: {
              [action.payload.filterKey]: `${action.payload.filterValue.value}`,
              offset: 0,
            },
          },
        }),
      };

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        gaData: {
          clickType: ClickType.FILTER,
          label: `${action.payload.filterKey}:${action.payload.filterValue.value}`,
        },
        paramsChanged,
        postcodeChanged: false,
        dataLoading: paramsChanged,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.CHANGE_FILTER: {
      // target is always HTMLElement here and never EventTarget
      const { value, filterType, filterKey } = getDataset(action.payload.target as HTMLElement);
      // this value is defined only if target is select element
      let filterValue = value || (action.payload.target as HTMLSelectElement).value;
      // For multi select filters we want to add to the values & not replace them

      if (filterType === 'multiSelect') {
        filterValue = getFilterValueMultiSelect({
          selectedFilters: state.selectedFilters,
          activeTab: state.activeTab,
          filterKey,
          value,
        });
      }

      const newSelectedFilters = {
        ...state.defaultSelectedFilters,
        ...getNewSelectedFilters({
          tabName: state.activeTab?.value,
          tab: state.selectedFilters[state.activeTab?.value],
          filters: {
            params: {
              [filterKey as string]: filterValue,
              offset: 0,
            },
            selected: {
              // Ensure we don't populate the input when the active filter for a model is removed
              [filterKey as string]: filterKey === 'model_name' ? '' : filterValue,
              offset: 0,
            },
          },
        }),
      };

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        gaData: { clickType: ClickType.FILTER },
        selectedFilters: newSelectedFilters,
        paramsChanged,
        postcodeChanged: false,
        dataLoading: paramsChanged,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.SHOW_FEWER_DEALS: {
      const newSelectedFilters = {
        ...state.defaultSelectedFilters,
        ...getNewSelectedFilters({
          tabName: state.activeTab?.value,
          tab: state.selectedFilters[state.activeTab?.value],
          filters: {
            params: {
              offset: 0,
            },
            selected: {
              offset: 0,
            },
          },
        }),
      };

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        gaData: { clickType: 'Reset deals' },
        paramsChanged,
        postcodeChanged: false,
        dataLoading: paramsChanged,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.LOAD_MORE_DEALS: {
      const offset = getOffset(state.selectedFilters, state.activeTab, state.activeTab?.pageSize);

      const newSelectedFilters = {
        ...state.defaultSelectedFilters,
        ...getNewSelectedFilters({
          tabName: state.activeTab?.value,
          tab: state.selectedFilters[state.activeTab?.value],
          filters: {
            params: {
              offset,
            },
            selected: {
              offset,
            },
          },
        }),
      };

      const paramsChanged = !stringCompare(
        state.selectedFilters[state.activeTab?.value]?.params,
        newSelectedFilters[state.activeTab?.value]?.params,
      );

      return {
        ...state,
        selectedFilters: newSelectedFilters,
        gaData: action.payload ? { clickType: 'Load more' } : {},
        dataLoading: paramsChanged,
        paramsChanged,
        // TODO: These two properties should be handled in particular components
        currentParams: newSelectedFilters[state.activeTab?.value]?.params || {},
        currentSelectedFilters: newSelectedFilters[state.activeTab?.value]?.selected || {},
      };
    }
    case ActionTypes.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case ActionTypes.CHANGE_TAB: {
      // target is always HTMLElement here and never EventTarget
      const { value: dataValue } = getDataset(action.payload.target as HTMLElement);
      // Support the tab that uses the Filter component (Pocket) or HawkTabs component
      // target.value is defined if target is select element
      const value = (action.payload.target as HTMLSelectElement).value || dataValue;
      const newTab = state.tabConfigs.find((tab: Tab) => tab.value === value);

      if (newTab) {
        // Store the tab in a cookie so this tab be loaded by default
        setCookie('hawk-review-widget-tab', newTab.value);

        const paramsChanged = !stringCompare(
          state.selectedFilters[state.activeTab?.value]?.params,
          state.selectedFilters[newTab.value]?.params,
        );

        return {
          ...state,
          activeTab: newTab,
          paramsChanged,
          postcodeChanged: false,
          dataLoading: paramsChanged,
          // TODO: These two properties should be handled in particular components
          currentParams: state.selectedFilters[newTab.value]?.params || {},
          currentSelectedFilters: state.selectedFilters[newTab.value]?.selected || {},
          sortOptions:
            typeof state.getSortOptions === 'function' ? state.getSortOptions(newTab) : [],
        };
      }

      return state;
    }
    case ActionTypes.SCROLL_RIGHT: {
      const newSelectedItem = getSwitcherItem(
        'right',
        state.switcherItems,
        state.selectedSwitcherItem,
      );

      return {
        ...state,
        selectedSwitcherItem: newSelectedItem,
      };
    }
    case ActionTypes.SCROLL_LEFT: {
      const newSelectedItem = getSwitcherItem(
        'left',
        state.switcherItems,
        state.selectedSwitcherItem,
      );

      return {
        ...state,
        selectedSwitcherItem: newSelectedItem,
      };
    }
    case ActionTypes.SORT_SIMILAR_TO_LAST_POSITION: {
      return {
        ...state,
        data: {
          ...state.data,
          [state.activeTab?.value]: {
            ...state.data[state.activeTab?.value],
            offers: action.payload.data?.offers
              ? action.payload.data.offers
              : state.data[state.activeTab?.value]?.offers,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};
