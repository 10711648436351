import React from 'react';

interface IAnimationWrapperProps {
  children?: React.ReactNode;
}

const AnimateWrapper: React.FC<IAnimationWrapperProps> = (props) => {
  const { children } = props;

  return <>{children}</>;
};

export default AnimateWrapper;
