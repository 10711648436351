import Feature from '../../types/Feature';
import FeatureId from '../../types/FeatureId';

const getAttributeValue = <T>(feature: Feature, attributes: Record<string, T>): T | null => {
  if (
    attributes[feature?.id] &&
    feature?.id !== FeatureId.WIDGET_TYPE &&
    feature?.id !== FeatureId.WIDGET_TITLE
  ) {
    return attributes[feature?.id];
  }
  return null;
};

export default getAttributeValue;
