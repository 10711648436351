import React from 'react';

import type { DealData } from '../../../types/DealData';

import styles from './styles/tracking-pixel.css';

interface TrackingPixelProps {
  dealData: DealData;
}

const TrackingPixel: React.FC<TrackingPixelProps> = ({ dealData }) => {
  const currentTimeStamp = Date.now().toString();

  if (dealData?.deals) {
    // if there are deals with same tracking pixel then we deduplicate them
    // and display them only once
    const uniqueValues = [
      ...new Map(dealData?.deals.map((deal) => [deal.tracking_pixel_url, deal])).values(),
    ];

    return (
      <>
        {uniqueValues &&
          uniqueValues.map((deal): JSX.Element | null => {
            if (deal?.tracking_pixel_url) {
              return (
                <span className={styles.container} key={deal?.id}>
                  <img
                    src={deal?.tracking_pixel_url.replace('hawk-cache', currentTimeStamp)}
                    alt=""
                    className={styles.image}
                    draggable="false"
                    width={0}
                    height={0}
                  />
                </span>
              );
            }
            return null;
          })}
      </>
    );
  }
  return null;
};

export default TrackingPixel;
