import he from 'he';

import { WidgetData } from '../model';

import { processDealWidgetImage } from './processDealWidgetImage';
import { processDealWidgetLayout } from './processDealWidgetLayout';
import { processDealWidgetLink } from './processDealWidgetLink';
import { processDealWidgetModel } from './processDealWidgetModel';
import { processDealWidgetPromo } from './processDealWidgetPromo';
import { processDealWidgetStar } from './processDealWidgetStar';

export const processDealWidgetData = (widgetData: WidgetData): Record<string, string> => ({
  ...processDealWidgetImage(widgetData.data?.image?.src),
  ...processDealWidgetLink(widgetData.data?.link),
  ...processDealWidgetStar(widgetData.data?.star, widgetData.data?.starLabel),
  ...processDealWidgetLayout(widgetData.data?.layout),
  ...processDealWidgetModel(widgetData.data?.model),
  ...processDealWidgetPromo(widgetData.data?.promo),
  ...(widgetData.data?.title ? { 'widget-title': widgetData.data.title } : {}),
  ...(widgetData.data?.text ? { 'widget-introduction': widgetData.data.text } : {}),
  ...(widgetData.data ? { 'widget-type': 'deal' } : {}),
  ...(widgetData.data?.price
    ? { 'widget-price': he.encode(JSON.stringify(widgetData.data.price)) }
    : {}),
  ...(widgetData.data?.matchId ? { 'match-id': widgetData.data.matchId } : {}),
  ...(widgetData.excludeFrom &&
  widgetData.excludeFrom.length > 0 &&
  widgetData.excludeFrom.includes('all')
    ? { 'exclude-from': 'all' }
    : {}),
});
