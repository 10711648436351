import type { Deal } from '../types/Deal';

/**
 * Get the translated display labels
 */
export const getDisplayLabels = (deal: Deal): string | null => {
  if (deal?.offer?.display_labels) {
    return deal.offer.display_labels;
  }
  return null;
};

export const getDisplayName = (deal: Deal): string => {
  const modelName = deal?.model;
  const labels = getDisplayLabels(deal);
  if (labels) {
    return `${modelName} ${labels}`;
  }
  return `${modelName}`;
};
