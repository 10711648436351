import type { APIData } from '../types/APIData';

export default (data: APIData, models: Record<string, number> = {}): string => {
  // Get the model name for each requested model
  // Return the default title (all models in response) if we can't find the model names
  const title = Object.values(models)
    .map((modelId) => data.model_info && data.model_info[modelId] && data.model_info[modelId].name)
    .filter((model) => model)
    .join(', ');

  if (title && title.indexOf(',') >= 0) {
    return `${title.substring(0, title.lastIndexOf(','))} and ${title
      .substring(title.lastIndexOf(','))
      .replace(', ', '')}`;
  }
  if (title) {
    return title;
  }

  return data.title;
};
