interface AirdaleParams {
  site: string;
  nb_results: number;
  articleVoucherRetailerCategory: string;
  fl: string;
  articleTerritory: string;
}

export const getAirdaleParams = (
  site: string,
  length: number,
  retailerCategory: string,
  territory: string,
  fields = 'articleVoucherRetailerCategory,canonical',
): AirdaleParams => ({
  site,
  nb_results: length,
  articleVoucherRetailerCategory: retailerCategory,
  fl: fields,
  articleTerritory: territory,
});
