import { getNodes } from './getNodes';

export default (): void => {
  const hawkPlaceholders = getNodes({
    selector: '.hawk-nest[data-render-type="editorial"][data-widget-type="overlay"]',
    context: document,
  });
  const imageContainer = getNodes({
    selector:
      '.hero-image-wrapper > div, .image--hero > div:first-child, .jwplayer-herowrapper, .jwplayer__wrapper',
    context: document,
  });

  if (imageContainer && hawkPlaceholders && imageContainer.length === hawkPlaceholders.length) {
    imageContainer.forEach((node, index) => {
      node.appendChild(hawkPlaceholders[index]);
    });
  }
};
